import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Redirect,
} from "react-router-dom";

import Home from './app/Home/Home';
import LandingPage from './app/Login/LandingPage';
import Docs from './app/Login/Docs';
import Pricing from './app/Login/Pricing';

import PaymentSuccess from './app/Payment/PaymentSuccess';
import PaymentFailed from './app/Payment/PaymentFailed';
import Notification from './app/Payment/Notification';
import Footer from './app/Components/Footer';
import Confidentiel from './app/Components/Confidentiel';


function App() {
    
    return (
        <>
        <Router>
            <Switch>
                <Route path="/home">
                    <Home />
                </Route>
                <Route exact path="/how-to-use-it">
                    <Docs />
                </Route>

                <Route exact path="/pricing">
                    <Pricing />
                </Route>
                <Route exact path="/politique-de-confidentialite">
                    <Confidentiel />
                </Route>
                <Route path="/payment-success/Id/:id1/sId/:id2">
                    <PaymentSuccess />
                </Route>

                <Route path="/payment-failed">
		            <Home />

                </Route>

                <Route path="/notification">
                    <Notification />
                </Route>
               
                <Route path="/:tokenMembership?">
                    <LandingPage />
                </Route>
            
            </Switch>
             <Footer/> 
        </Router>
   </> );
}

export default App;

