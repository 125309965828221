import React from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'

export default function PaymentSuccess(props) {

    axios.post('https://imodo.ai/payment-success')
    .then(res => {
        console.log(res)
    })

    return (
        <div>

        </div>
    )
}
