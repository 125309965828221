import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Navbar from '../Navbar'
// Translations
import { trans } from '../../../Translations'
import './style.css'
import ConfidentFr from './ConfidentFr'
import ConfidentEng from './ConfidentEng'

const Confidentiel = (props) => {
	const lang = useSelector((state) => state.socialMediaR.lang)
	const [showContact, setShowContact] = useState(false)
	const allMenuItems = [
		{ title: trans[lang].navbar.contactUs, route: 'contact-link' },
		{ title: trans[lang].navbar.howToUseIt, route: 'how-to-use-it-home' },
		{ title: trans[lang].navbar.pricing, route: 'pricing' },
	]
	return (
		<>
			<Navbar menuItems={allMenuItems} loginBtn={true} setShowContact={setShowContact} />
			<main id="confident">{lang == 'en' ? <ConfidentEng /> : <ConfidentFr />}</main>
		</>
	)
}

export default Confidentiel
