import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Toast, Image } from 'react-bootstrap'
import { BsFillBellFill } from 'react-icons/bs'

const LOGO = require('../../assets/images/home/logo_white.svg')

function ToastNotification({ billingPlan }) {
	const [showNotificationRemain, setShowNotificationRemain] = useState(true)
	const [showNotificationNotif, setShowNotificationNotif] = useState(true)
	//const [showNotificationRemaintr, setShowNotificationRemaintr] = useState(true)
	const [showNotificationUpgr, setShowNotificationUpgr] = useState(true)
	const [type, setType] = useState()
	const [remainDays, setRemainDays] = useState()
	const [hasVisited, sethasVisited] = useState(JSON.parse(localStorage.getItem('hasVisited')) || false)
	const data = [
		{ number: '07', text: 'Remaining days of current plan', bgColor: '#139216', colorText: '#139216' },
		{ LOGO, text: 'Notification for renewal', bgColor: '#e5007d', colorText: '#e5007d' },
		{ number: '04', text: 'Remaining trial days', bgColor: '#4080ff', colorText: '#4080ff' },
		{ text: 'Upgrade your plan', bgColor: '#e60a17', colorText: '#e60a17' },
	]
	useEffect(() => {
		if (billingPlan) {
			var currentDate = moment()
			var date = moment(billingPlan?.expyriedate)
			var day_expire = date.diff(currentDate, 'days')
			console.log('date', day_expire)
			setType(billingPlan?.name)
			setRemainDays(day_expire)
		}
	}, [billingPlan])
	useEffect(() => {
		localStorage.setItem('hasVisited', true)
	}, [])
	return (
		hasVisited || (
			<div
				aria-live="polite"
				aria-atomic="true"
				style={{
					position: 'relative',
					//minHeight: '200px',
				}}
			>
				{console.log(billingPlan)}
				<div
					style={{
						position: 'absolute',
						top: '20px',
						right: '10%',
						zIndex: 10,
					}}
				>
					{remainDays > 0 && (
						<Toast
							delay={5000}
							autohide
							//animation
							onClose={() => setShowNotificationRemain(false)}
							show={showNotificationRemain}
							className="d-flex border-2"
							style={{
								borderColor: `${data[0]?.bgColor}`,
							}}
						>
							<Toast.Body
								className="d-flex p-0"
								style={{
									minHeight: '13vh',
								}}
							>
								<div
									className="d-flex p-3"
									style={{
										backgroundColor: `${data[0]?.bgColor}`,
									}}
								>
									<div className="align-self-center text-center" style={{ fontSize: '57px', minWidth: '4vw', color: '#fff' }}>
										{remainDays}
									</div>
								</div>
								<div
									className="align-self-center p-2 font-weight-bold text-center"
									style={{
										color: `${data[0]?.colorText}`,
										fontSize: '17px',
									}}
								>
									{data[0]?.text}
								</div>
								<a href="#" onClick={() => setShowNotificationRemain(false)} className="close-notif align-self-start p-1"></a>
							</Toast.Body>
						</Toast>
					)}
					{remainDays <= 3 && remainDays >= 0 && (
						<Toast
							delay={5000}
							autohide
							//animation
							onClose={() => setShowNotificationNotif(false)}
							show={showNotificationNotif}
							className="d-flex border-2"
							style={{
								borderColor: `${data[1]?.bgColor}`,
							}}
						>
							<Toast.Body
								className="d-flex p-0"
								style={{
									minHeight: '13vh',
								}}
							>
								<div
									className="d-flex p-3"
									style={{
										backgroundColor: `${data[1]?.bgColor}`,
									}}
								>
									<Image
										src={data[1]?.LOGO}
										className={'align-self-center'}
										style={{
											maxWidth: '80px',
											minWidth: '4vw',
										}}
									/>
								</div>
								<div
									className="align-self-center p-2 font-weight-bold text-center"
									style={{
										color: `${data[1]?.colorText}`,
										fontSize: '17px',
									}}
								>
									{data[1]?.text}
								</div>
								<a href="#" onClick={() => setShowNotificationNotif(false)} className="close-notif align-self-start p-1"></a>
							</Toast.Body>
						</Toast>
					)}
					{/* {type == 'Free Trial' && remainDays > 0 && (
						<Toast
							delay={5000}
							autohide
							//animation
							onClose={() => setShowNotificationRemaintr(false)}
							show={showNotificationRemaintr}
							className="d-flex border-2"
							style={{
								borderColor: `${data[2]?.bgColor}`,
							}}
						>
							<Toast.Body
								className="d-flex p-0"
								style={{
									minHeight: '13vh',
								}}
							>
								<div
									className="d-flex p-3"
									style={{
										backgroundColor: `${data[2]?.bgColor}`,
									}}
								>
									<div className="align-self-center text-center" style={{ fontSize: '57px', minWidth: '4vw', color: '#fff' }}>
										{remainDays}
									</div>
								</div>
								<div
									className="align-self-center p-2 font-weight-bold text-center"
									style={{
										color: `${data[2]?.colorText}`,
										fontSize: '17px',
									}}
								>
									{data[2]?.text}
								</div>
								<a href="#" onClick={() => setShowNotificationRemaintr(false)} className="close-notif align-self-start p-1"></a>
							</Toast.Body>
						</Toast>
					)} */}
					{remainDays < 0 && (
						<Toast
							delay={5000}
							autohide
							//animation
							onClose={() => setShowNotificationUpgr(false)}
							show={showNotificationUpgr}
							className="d-flex border-2"
							style={{
								borderColor: `${data[3]?.bgColor}`,
							}}
						>
							<Toast.Body
								className="d-flex p-0"
								style={{
									minHeight: '13vh',
								}}
							>
								<div
									className="d-flex p-3"
									style={{
										backgroundColor: `${data[3]?.bgColor}`,
									}}
								>
									<BsFillBellFill className="align-self-center" style={{ fontSize: '57px', minWidth: '4vw', color: '#fff' }} />
								</div>
								<div
									className="align-self-center p-2 font-weight-bold text-center"
									style={{
										color: `${data[3]?.colorText}`,
										fontSize: '17px',
									}}
								>
									{data[3]?.text}
								</div>
								<a href="#" onClick={() => setShowNotificationUpgr(false)} className="close-notif align-self-start p-1"></a>
							</Toast.Body>
						</Toast>
					)}
				</div>
			</div>
		)
	)
}
export default ToastNotification
