import './Login.css'

import { Col, Container, Form, Image, Modal, Row } from 'react-bootstrap'
import { Link, Navlink, Redirect, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import Facebook from './Facebook'
import { IoIosClose } from 'react-icons/io'
import Navbar from '../Components/Navbar'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'
import { connect } from 'react-redux'
import { host } from '../../config'
import { trans } from '../../Translations'
import { useMediaQuery } from 'react-responsive'

// Components :

// Translations

// Icons & Images:

const LANDING_IMG = require('../../assets/images/login/landingg-img.png')
const PREBUILT_SOLUTION = require('../../assets/images/home/prebuilt-solution.png')
const SMILE_HEART_IMG = require('../../assets/images/home/smile-heart.png')
const MKHININI_HOME = require('../../assets/images/home/mkhinini-home.png')
const DIGI_POWER = require('../../assets/images/home/digi-power.png')
const IFRIKYA = require('../../assets/images/home/ifrikya.png')
const SEMHA = require('../../assets/images/home/semha.png')
const CAMPUS = require('../../assets/images/home/campus.png')
const MANAGEADS = require('../../assets/images/home/manage-ad.png')
const CHECKED = require('../../assets/images/home/checked-list.png')
const BULLET = require('../../assets/images/home/bullet.png')
const TIME = require('../../assets/images/home/time.png')
const STATIC = require('../../assets/images/home/static.png')
const CONVER = require('../../assets/images/home/conver.png')
const COSTOMERS = require('../../assets/images/home/customers.png')
const BISNESS1 = require('../../assets/images/home/bisness1.png')
const BISNESS3 = require('../../assets/images/home/bisness3.png')
const BISNESS2 = require('../../assets/images/home/bisness2.png')

const BXSSEND = require('../../assets/images/home/bxs_send.png')
const LOGO = require('../../assets/images/home/logo.svg')

const FB_ICON = require('../../assets/images/footer_facebook.svg');
const YOUTUBE_ICON = require('../../assets/images/footer_youtube.svg');  
const LINKEDIN_ICON = require('../../assets/images/footer_linkedin.svg') 
const INSTA_ICON = require('../../assets/images/footer_instagram.svg'); 

const LANDING_IMG_BG = require('../../assets/images/login/landing-img-bg.jpg')
const MIND_ICON = require('../../assets/images/login/mind.png')
const CHATBOT_ICON = require('../../assets/images/login/chatbot.png')
const CORE_AI_ICON = require('../../assets/images/login/coreai.svg')

function LandingPage(props) {
	let { lang } = props
	const history = useHistory()
	const isMobile = useMediaQuery({ maxWidth: 516 })
	// Popup Config :
	const popup = Swal.mixin({
		customClass: {
			confirmButton: 'wizard-pages-active-btn py-2 px-3',
		},
		buttonsStyling: false,
	})

	const [showContact, setShowContact] = useState(false)
	let [contactName, setContactName] = useState('')
	let [contactLastName, setContactLastName] = useState('')
	let [contactOrg, setContactOrg] = useState('')
	let [contactEmail, setContactEmail] = useState('')
	let [contactPhone, setContactPhone] = useState('')
	let [contactSubject, setContactSubject] = useState('')
	let [contactMsg, setContactMsg] = useState('')
	const [showPopup, setShowPopup] = useState(true)
	const auth_token = localStorage.getItem('authtoken')
	const userData = JSON.parse(localStorage.getItem('userData'))

	const allMenuItems = [
		{ title: trans[lang].navbar.contactUs, route: 'contact-link' },
		{ title: trans[lang].navbar.whatIsModo, route: 'prebuilt-solution' },
		{ title: trans[lang].navbar.howToUseIt, route: 'how-to-use-it-home' },
		{ title: trans[lang].navbar.pricing, route: 'pricing' },
	]

	const isValidEmail = (email) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	const sendContactForm = (newsletter) => {
		if (contactName === '' && !newsletter) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validNameErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactLastName === '' && !newsletter) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validLastNameErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactOrg === '' && !newsletter) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validOrgErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (!isValidEmail(contactEmail)) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validEmailErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactPhone === '' && !newsletter) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validPhoneErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactSubject === '' && !newsletter) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validSubjectErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactMsg === '' && !newsletter) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validMsgErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else {
			// SEND THE CONTACT FORM

			let objectToSend = {
				name: contactName,
				last_name: contactLastName,
				org: contactOrg,
				email: contactEmail,
				phone: contactPhone,
				subject: contactSubject,
				msg: contactMsg,
				status: false,
			}
			if(newsletter){
				objectToSend.name =  '----' 
				objectToSend.last_name =  '----' 
				objectToSend.org =  '----' 
				objectToSend.email =  contactEmail
				objectToSend.phone =  '----' 
				objectToSend.subject =  'Newsletter' 
				objectToSend.msg =  'Newsletter' 
				objectToSend.status =  false
			}
			console.log(objectToSend)
			// console.log("im sending to nour", objectToSend);

			axios
				.post(host + `/api/v1/contact/addContact`, objectToSend)
				.then((res) => {
					console.log('/api/v1/contact/addContact APi Response =>', res.data.data)
					popup.fire({
						icon: 'success',
						title: trans[lang].landingPage.contactSuccess,
						confirmButtonText: trans[lang].landingPage.confirmContact,
					})
					setShowContact(false)
				})
				.catch((err) => {
					console.log('/api/v1/contact/addContact APi post Api error  =>', err)
					popup.fire({
						icon: 'error',
						title: trans[lang].landingPage.conexErr,
						confirmButtonText: trans[lang].landingPage.retry,
					})
				})
		}
	}
	const ShowPopup = () => (
		<Modal className="show-video" show={showPopup} size={'lg'} centered>
			<Modal.Body style={{ background: '#F7F7F7', borderRadius: '20px' }}>
				<Row>
					<Col lg={12} sm={12} className="d-flex mb-1">
						<IoIosClose
							color={'#B4B4B4'}
							size={30}
							className="ml-auto"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setShowPopup(false)
							}}
						/>
					</Col>
					<Col lg={12} sm={12} className="d-flex mb-2">
						<iframe
							className="col-lg-12"
							src={trans[lang].docs.overview.video}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	)
	// Rendering Methods
	const renderContactModal = () => (
		<Modal show={showContact} size={'lg'} centered>
			<Modal.Body style={{ background: '#F5F5F5', borderRadius: '8px' }}>
				<Col lg={12}>
					<Row>
						<Col lg={12} className="d-flex mb-1">
							<IoIosClose
								color={'#B4B4B4'}
								size={30}
								className="ml-auto"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setShowContact(false)
								}}
							/>
						</Col>

						<Col lg={12} className="d-flex mb-2">
							<p className="m-auto contact-us-title">{trans[lang].landingPage.contactUs}</p>
						</Col>

						<Col lg={12}>
							<Form>
								<Form.Row className="mb-3">
									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.firstName} *</Form.Label>
										<Form.Control onChange={(e) => setContactName(e.target.value)} id="contact-form-input" />
									</Col>

									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.lastName} *</Form.Label>
										<Form.Control onChange={(e) => setContactLastName(e.target.value)} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="mb-3">
									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.org} *</Form.Label>
										<Form.Control onChange={(e) => setContactOrg(e.target.value)} id="contact-form-input" />
									</Col>

									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.email} *</Form.Label>
										<Form.Control onChange={(e) => setContactEmail(e.target.value)} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="mb-3">
									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.phone}</Form.Label>
										<Form.Control type="number" onChange={(e) => setContactPhone(e.target.value)} id="contact-form-input" />
									</Col>

									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.subject}</Form.Label>
										<Form.Control onChange={(e) => setContactSubject(e.target.value)} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="mb-3">
									<Col lg={12}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.message}</Form.Label>
										<Form.Control onChange={(e) => setContactMsg(e.target.value)} as="textarea" rows={3} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="justify-content-center mb-2">
									<div className="contact-form-button py-2 text-center" onClick={() => sendContactForm()}>
										{trans[lang].landingPage.send}
									</div>
								</Form.Row>
							</Form>
						</Col>
					</Row>
				</Col>
			</Modal.Body>
		</Modal>
	)

	return (
		<>
			{auth_token !== null && userData !== null && <Redirect to="/home/dashboard" />}
			<Navbar menuItems={allMenuItems} loginBtn={true} setShowContact={setShowContact} />
			{/* <ShowPopup /> */}
			<Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
				{showContact && renderContactModal()}
				{/* BLOC 1 */}
				<Row
					className="mb-5 justify-content-center flex-column-reverse flex-sm-row"
					style={{
						/* background: '#F9F9F9', */
						paddingTop: isMobile ? '0%' : '5%',
						paddingBottom: isMobile ? '0%' : '1%',
					}}
				>
					{/* <Row className="mb-5 justify-content-center" style={{background: "#F9F9F9" , padding: '63px 0px',marginTop: '100px'}}> */}
					<Col lg={5} className={'d-flex present-home'} style={{ paddingTop: isMobile ? '0%' : '6em', marginLeft: isMobile ? '0%' : '2%' }}>
						{/* <div className="m-auto d-flex justify-content-between flex-column"> */}
						<Row className="mt-4 ">
							<Col lg={12} className="d-flex">
								<p className="landing-big-text ">
									{trans[lang].landingPage.turn} <br /> {trans[lang].landingPage.valueFor}
								</p>
							</Col>
							<Col lg={12} className="d-flex">
								<p className="landing-desc" style={{ color: '#6d6d6d' }}>
									{trans[lang].landingPage.readyTo} <br />
									{trans[lang].landingPage.preconf} <br />
									{trans[lang].landingPage.support}
								</p>
							</Col>
							<Col lg={12} className="d-flex">
								<p className="landing-desc" style={{ color: '#E5007D' }}>
									{trans[lang].landingPage.autoResp} <br />
									{trans[lang].landingPage.winback}
								</p>
							</Col>
							<Col lg={12} className="d-flex mt-3">
								<Facebook size="large" active content={trans[lang].landingPage.myFreeTrial} />

								<a href="#contact">
									<button className={'facebookbtn large'} style={{ width: '100%', color: '#009FE3', borderRadius: '11px' }}>
										{trans[lang].landingPage.askFor}
									</button>
								</a>
							</Col>
							{/* <Col lg={12} className="d-flex mt-3">
									<p className="no-credit-required">** {trans[lang].landingPage.noCard}</p>
								</Col> */}
						</Row>
						{/* </div> */}
					</Col>

					<Col lg={6} className={'d-flex'} style={{ backgroundColor: '' }}>
						<Row>
							<Col
								lg={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									textAlign: 'center',
									borderRadius: '10px',
									//marginLeft: '11%',
									/* boxShadow: '0 4px 11px -2px #f9637e', */
									/* backgroundImage: `url(${LANDING_IMG_BG})` */
								}}
							>
								<Image className={'mx-auto mt-2 image-landing-page'} src={LANDING_IMG} />
							</Col>

							{/* <Col lg={12} className="d-flex">
								<p className="m-auto landing-page-slogan">{trans[lang].landingPage.schedAgent}</p>
							</Col> */}
						</Row>
					</Col>
				</Row>

				{/* CARDS */}
				{/* <a id="what-is-modo" ></a> */}
				<section id="our-solution">
					<Row className="justify-content-center">
						<Col sm={12} style={{ textAlign: 'center' }}>
							<Image style={{ width: '40px' }} src={SMILE_HEART_IMG} />
						</Col>
						<Col sm={12} style={{ color: '#009FE3', textAlign: 'center', fontFamily: 'Roboto Medium', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '25px' : '35px'  }}>
							{trans[lang].landingPage.theyHad}
						</Col>
						<Col sm={12} style={{ color: '#000', textAlign: 'center', fontFamily: 'Roboto Medium', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '24px' : '32px'  }}>
							{trans[lang].landingPage.ourSolution}
						</Col>
						<Col sm={12}>
							<Row className="list justify-content-around">
								<Image src={IFRIKYA} />
								<Image src={CAMPUS} />
								<Image src={MKHININI_HOME} />
								<Image src={SEMHA} />
								<Image src={DIGI_POWER} />
							</Row>
						</Col>
					</Row>
				</section>
				<section id="prebuilt-solution" style={{ background: '#fdfdfd', paddingTop: '2em' }}>
					<Row>
						<Col sm={12} style={{ color: '#000', textAlign: 'center', fontFamily: 'Roboto Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '24px' : '32px'  }}>
							{trans[lang].landingPage.prebuiltSolution}
						</Col>
						<Col sm={12} style={{ marginTop: '1em', color: '#303030', textAlign: 'center', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: '18px', textTransform: 'uppercase' }}>
							{trans[lang].landingPage.prefUnder}
							<br />
							{trans[lang].landingPage.severalLang}
						</Col>
						<Col sm={12} style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}>
							<Image width="100%" src={PREBUILT_SOLUTION} />
						</Col>
					</Row>
				</section>
				<section id="how-make" style={{ position: 'relative' }}>
					<div className="rectangle" style={{ marginTop: '4em' }}></div>
					<Row className="list">
						<Col sm={12} style={{ color: '#000', textAlign: 'center', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>
							{trans[lang].landingPage.howMake}
						</Col>
						<Col sm={12} style={{ color: '#000', textAlign: 'center', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '24px' : '32px'  }}>
							{trans[lang].landingPage.commentsEasier}
						</Col>
						<Row className="list" style={{ marginTop: '2em' }}>
							<Col sm={6} style={{ textAlign: 'center' }}>
								<Image style={{ width: isMobile ? '100%' : '84%', height: isMobile ? '100%' : '84%',objectFit:'contain' }} src={MANAGEADS} />
							</Col>
							<Col sm={6}>
								<p style={{ lineHeight: 1.5, color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: '20px' }}>{trans[lang].landingPage.ifYou}</p>
								<ul style={{ listStyle: 'none', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '400', fontSize: '20px' }}>
									<li>
										<Image style={{ marginLeft: '13px', marginRight: '20px' }} width="20px" src={BULLET} />
										{trans[lang].landingPage.relyOn}
									</li>
									<li>
										<Image style={{ marginLeft: '13px', marginRight: '20px' }} width="20px" src={BULLET} />
										{trans[lang].landingPage.handleVolum}
									</li>
									<li>
										<Image style={{ marginLeft: '13px', marginRight: '20px' }} width="20px" src={BULLET} />
										{trans[lang].landingPage.struggleWith}
									</li>
									<li>
										<Image style={{ marginLeft: '13px', marginRight: '20px' }} width="20px" src={BULLET} />
										{trans[lang].landingPage.cantAfford}
									</li>
								</ul>
								<p style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: '20px' }}>{trans[lang].landingPage.modoSolution}</p>
								<ul style={{ marginBottom: '1.5em', listStyle: 'none', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '400', fontSize: '20px' }}>
									<li>
										<Image width="25px" src={CHECKED} style={{ marginLeft: '13px', marginRight: '20px' }} />
										{trans[lang].landingPage.boostEffect}
									</li>
									<li>
										<Image width="25px" src={CHECKED} style={{ marginLeft: '13px', marginRight: '20px' }} />
										{trans[lang].landingPage.autoRep}
									</li>
									<li>
										<Image width="25px" src={CHECKED} style={{ marginLeft: '13px', marginRight: '20px' }} />
										{trans[lang].landingPage.autoHide}
									</li>
									<li>
										<Image width="25px" src={CHECKED} style={{ marginLeft: '13px', marginRight: '20px' }} />
										{trans[lang].landingPage.scaleOper}
									</li>
								</ul>
								<Facebook size="large" active content={trans[lang].landingPage.myFreeTrial} />
							</Col>
						</Row>
					</Row>
				</section>
				<section id="process" style={{ position: 'relative' }}>
					<Row>
						<Col className="title" sm={12} style={{ color: '#000', textAlign: 'center', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '24px' : '32px' }}>
							{trans[lang].landingPage.process}
						</Col>
						<Col sm={1} style={{ background: 'rgba(255, 237, 0, 0.11)' }}></Col>
						<Col className="height-color" sm={{ span: 10 }} style={{ height: '15rem' }}>
							<Row className="justify-content-around mt-5">
								<Row className="justify-content-center">
									<Row className="justify-content-center" style={{ width: '90px', height: '90px', padding: '2%', borderRadius: '50%', boxShadow: ' 0px 0px 67px rgba(0, 0, 0, 0.08)' }}>
										<Image style={{ width: '60px', objectFit: 'contain' }} src={TIME} />
									</Row>
									<div className="mt-4" style={{ textAlign: 'center', textTransform: 'uppercase', width: '100%', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '16px' : '18px' }}>
										{trans[lang].landingPage.saveTime}
									</div>
								</Row>
								<Row className="justify-content-center">
									<Row className="justify-content-center" style={{ width: '90px', height: '90px', padding: '2%', borderRadius: '50%', boxShadow: ' 0px 0px 67px rgba(0, 0, 0, 0.08)' }}>
										<Image style={{ width: '60px', objectFit: 'contain' }} src={STATIC} />
									</Row>
									<div className="mt-4" style={{ textAlign: 'center', textTransform: 'uppercase', width: '100%', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '16px' : '18px' }}>
										{trans[lang].landingPage.boostYour}
										<br />
										{trans[lang].landingPage.sales}
									</div>
								</Row>
								<Row className="justify-content-center">
									<Row className="justify-content-center" style={{ width: '90px', height: '90px', padding: '2%', borderRadius: '50%', boxShadow: ' 0px 0px 67px rgba(0, 0, 0, 0.08)' }}>
										<Image style={{ width: '60px', objectFit: 'contain' }} src={CONVER} />
									</Row>
									<div className="mt-4" style={{ textAlign: 'center', textTransform: 'uppercase', width: '100%', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '16px' : '18px' }}>
										{trans[lang].landingPage.improResp}
										<br />
										{trans[lang].landingPage.rate}
									</div>
								</Row>
								<Row className="justify-content-center">
									<Row className="justify-content-center" style={{ width: '90px', height: '90px', padding: '2%', borderRadius: '50%', boxShadow: ' 0px 0px 67px rgba(0, 0, 0, 0.08)' }}>
										<Image style={{ width: '60px', objectFit: 'contain' }} src={COSTOMERS} />
									</Row>
									<div className="mt-4" style={{ textAlign: 'center', textTransform: 'uppercase', width: '100%', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '16px' : '18px' }}>
										{trans[lang].landingPage.convMore}
										<br />
										{trans[lang].landingPage.customers}
									</div>
								</Row>
							</Row>
						</Col>
					</Row>
				</section>
				<section className="mt-5" id="trail-business" style={{ paddingTop: '4em' }}>
					<Row>
						<Col sm={12} style={{ color: '#000', textAlign: 'center', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize:isMobile ? '24px' : '32px' }}>
							{trans[lang].landingPage.tailored}
						</Col>
						<Col
							sm={12}
							style={{ textTransform: 'uppercase', position: 'relative', marginTop: '1em', color: '#303030', textAlign: 'center', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: '16px' }}
						>
							<div className="rectangle" style={{ background: 'rgba(255, 237, 0, 0.19)' }}></div>
							{trans[lang].landingPage.simplify}
						</Col>
						<Col sm={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Row style={{ justifyContent: 'center', alignItems: 'baseline' }}>
								<Col sm={{ span: 3, offset: 3 }} style={{ backgroundColor: '#FFED00', height: '10px', width: '255px' }}></Col>
								<Col sm={6} style={{ color: '#FFED00', fontFamily: 'Poppins Bold', fontWeight: '700', fontSize: '25px', width: 'auto' }}>
									1
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '23px' : '32px'}}>
									{trans[lang].landingPage.retailers}
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '24px' : '27px' }}>
									{trans[lang].landingPage.moreLeads}
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ marginTop: '1em', color: '#303030', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '18px' : '20px' }}>
									{trans[lang].landingPage.ourCom}
									<br />
									{trans[lang].landingPage.prospects}
									<br />
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ marginTop: '2em' }}>
									<Facebook size="large" active content={trans[lang].landingPage.myFreeTrial} />
								</Col>
							</Row>
						</Col>
						<Col sm={5} style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}>
							<Image width="100%" src={BISNESS1} />
						</Col>
					</Row>
				</section>
				<section className="mt-5" id="trail-business2" style={{ paddingTop: '1em', position: 'relative' }}>
					<div className="rectangle"></div>
					<Row>
						<Col sm={5} style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0, order: isMobile ? 2:0 }}>
							<Image width="100%" src={BISNESS2} />
						</Col>
						<Col sm={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Row style={{ justifyContent: 'center', alignItems: 'baseline' }}>
								<Col sm={{ span: 3, offset: 3 }} style={{ backgroundColor: '#E6007D', height: '8px', width: '255px' }}></Col>
								<Col sm={6} style={{ color: '#E6007D', fontFamily: 'Poppins Bold', fontWeight: '700', fontSize: '25px', width: 'auto' }}>
									2
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '23px' : '32px' }}>
									{trans[lang].landingPage.smallBusiness}
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '23px' : '27px' }}>
									{trans[lang].landingPage.getHelp}
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ marginTop: '1em', color: '#303030', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '18px' : '20px' }}>
									{trans[lang].landingPage.ourComSup}
									<br />
									{trans[lang].landingPage.products}
									<br />
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ marginTop: '2em' }}>
									<Facebook size="large" active content={trans[lang].landingPage.myFreeTrial} />
								</Col>
							</Row>
						</Col>
					</Row>
				</section>

				<section className="mt-5" id="trail-business3" style={{ paddingTop: '1em' }}>
					<Row>
						<Col sm={1} style={{ backgroundColor: 'rgba(0, 159, 227, 0.12)', height: '68px' }}></Col>
						<Col sm={11}></Col>
						<Col sm={7} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Row style={{ justifyContent: 'center', alignItems: 'baseline' }}>
								<Col sm={{ span: 3, offset: 3 }} style={{ backgroundColor: '#009FE3', height: '8px', width: '255px' }}></Col>
								<Col sm={6} style={{ color: '#009FE3', fontFamily: 'Poppins Bold', fontWeight: '700', fontSize: '25px', width: 'auto' }}>
									3
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '23px' : '32px'}}>
									{trans[lang].landingPage.digital}
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ color: '#000', fontFamily: 'Poppins Bold', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '23px' : '27px' }}>
									{trans[lang].landingPage.efficient}
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ marginTop: '1em', color: '#303030', fontFamily: 'Poppins Regular', fontStyle: 'normal', fontWeight: '400', fontSize: isMobile ? '18px' : '20px' }}>
									{trans[lang].landingPage.ourComBot}
									<br />
									{trans[lang].landingPage.manageClient}
									<br />
								</Col>
								<Col sm={{ span: 9, offset: 3 }} xs={{ span: 9, offset: 1 }} style={{ marginTop: '2em' }}>
									<Facebook size="large" active content={trans[lang].landingPage.myFreeTrial} />
								</Col>
							</Row>
						</Col>
						<Col sm={5} style={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}>
							<Image width="100%" src={BISNESS3} />
						</Col>
					</Row>
				</section>
				<section id="contact" style={{ background: '#FBFBFB', padding: '5%', position: 'relative' }}>
					<div className="rectangle"></div>
					<Row className="justify-content-center align-items-center ">
						<Col sm={4}>
							<Row style={{ lineHeight: 2 }}>
								<Col sm={6} className="mb-2">
									<label style={{ fontFamily: 'Poppins Medium', fontWeight: '700', fontSize: '15px' }}>{trans[lang].landingPage.firstName}</label>
									<br />
									<input
										onChange={(e) => setContactName(e.target.value)}
										placeholder={trans[lang].landingPage.firstName}
										style={{ background: '#FFFFFF', border: '1px solid #6B6B6B', borderRadius: '10px', width: '100%', height: '42px' }}
									/>
								</Col>
								<Col sm={6} className="mb-2">
									<label style={{ fontFamily: 'Poppins Medium', fontWeight: '700', fontSize: '15px' }}>{trans[lang].landingPage.lastName}</label>
									<br />
									<input onChange={(e) => setContactLastName(e.target.value)} placeholder="Last name" style={{ background: '#FFFFFF', border: '1px solid #6B6B6B', borderRadius: '10px', width: '100%', height: '42px' }} />
								</Col>
								<Col sm={12} className="mb-2">
									<label style={{ fontFamily: 'Poppins Medium', fontWeight: '700', fontSize: '15px' }}>{trans[lang].landingPage.yourMail} </label>
									<br />
									<input onChange={(e) => setContactEmail(e.target.value)} placeholder="E-mail" style={{ background: '#FFFFFF', border: '1px solid #6B6B6B', borderRadius: '10px', width: '100%', height: '42px' }} />
								</Col>
								<Col sm={12} className="mb-2">
									<label style={{ fontFamily: 'Poppins Medium', fontWeight: '700', fontSize: '15px' }}>{trans[lang].landingPage.org}</label>
									<br />
									<input onChange={(e) => setContactOrg(e.target.value)} placeholder="Organization" style={{ background: '#FFFFFF', border: '1px solid #6B6B6B', borderRadius: '10px', width: '100%', height: '42px' }} />
								</Col>
								<Col sm={12} className="mb-2">
									<label style={{ fontFamily: 'Poppins Medium', fontWeight: '700', fontSize: '15px' }}>{trans[lang].landingPage.phone}</label>
									<br />
									<input onChange={(e) => setContactPhone(e.target.value)} placeholder="Phone" style={{ background: '#FFFFFF', border: '1px solid #6B6B6B', borderRadius: '10px', width: '100%', height: '42px' }} />
								</Col>
								<Col>
									<button
										className={'facebookbtn facebookbtn-bg large'}
										style={{ width: '100%', marginTop: '4%', color: 'white', borderRadius: '11px' }}
										onClick={() => {
											alert('send')
											setContactSubject('demande demo')
											setContactMsg('demande demo')
											contactSubject = 'demande demo'
											contactMsg = 'demande demo'
											sendContactForm()
										}}
									>
										{trans[lang].landingPage.askFor}
									</button>
								</Col>
							</Row>
						</Col>
						<Col md={5} sm={7} style={{ marginLeft: '3rem' }}>
							<Row>
								<Col sm={12} style={{ lineHeight: 2, color: '#009FE3', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '700', fontSize: isMobile ? '28px' : '32px' }}>
									{trans[lang].landingPage.readyToUse}
								</Col>
								<Col className={isMobile ? 'mb-3' : ''} sm={12} style={{ color: '#000', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '400', fontSize: '20px' }}>
									{trans[lang].landingPage.ourTeam}
									<br />
									{trans[lang].landingPage.toolAutomate}
									<br />
								</Col>
								{/* <Col sm={12} style={{ lineHeight: 2, textTransform: 'uppercase', color: '#009FE3', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '700', fontSize: '32px' }}>
									{trans[lang].landingPage.contactUs}
								</Col>
								<Col sm={12} style={{ color: '#000', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '400', fontSize: '20px' }}>
									Tél : +216 23 555 222
									<br />
									Fax : +216 23 555 222
									<br />
									Email : modo@gmail.com
									<br />
								</Col>
								<Col sm={12} style={{ lineHeight: 2, textTransform: 'uppercase', color: '#009FE3', fontFamily: 'Poppins Medium', fontStyle: 'normal', fontWeight: '700', fontSize: '32px' }}>
									{trans[lang].landingPage.followUs}
								</Col>
								<Col sm={12}>
									<Image style={{ background: '#000000', borderRadius: '5px', padding: '0.5%', marginRight: '1%' }} width="20px" height="20px" src={FB_ICON} />
									<Image style={{ background: '#000000', borderRadius: '5px', padding: '0.5%',marginLeft:'2em' }} width="20px" height="20px" src={LINKEDIN_ICON} />
								</Col> */}
							</Row>
						</Col>
					</Row>
					<div className="rectanglee"></div>
				</section>
				<section id="footer-home" className=" d-flex justify-content-center" style={{ padding: isMobile ? '7% 1%' : '1% 5%' }}>
					<Row className="list justify-content-between align-content-center m-auto w-100 mx-5">
						<Link
							//className="m-auto"
							style={{
								display: 'flex',
								//marginLeft: '4%',
								//width: '10%',
							}}
							to={'/'}
						>
							<Image src={LOGO} className={'my-auto mr-2'} style={{ width: '200px' }} />
						</Link>
						<ul>
							<li>Menu</li>
							<li>
								<a href="" onClick={() => history.push('/pricing')}>
									{trans[lang].navbar.pricing}
								</a>
							</li>
							<li>
								<a href="#how-make">{trans[lang].navbar.whatIsModo}</a>
							</li>
							<li>
								<a href="#" onClick={() => setShowContact(true)}>
									{trans[lang].navbar.contactUs}
								</a>
							</li>
							<li>
								<a href="" onClick={() => history.push('/how-to-use-it')}>
									{trans[lang].navbar.howToUseIt}
								</a>
							</li>
						</ul>
						<ul>
							<li>Contact</li>
							{/* 	<li>Tél : +216 23 555 222</li>
							<li>Fax : +216 23 555 222</li> */}
							<li>Email : contact@imodo.ai</li>
							<li>
								<a target="_blank" href="https://www.facebook.com/commentsautoreply" target="_blank" title="Agorapulse Facebook page" rel="noopener">
									<Image style={{ background: '##efefef', borderRadius: '5px', padding: '2%', marginRight: '5%' }} width="35px" height="35px" src={FB_ICON} />
								</a>
								<a target="_blank" href="https://www.instagram.com/smartmodo.21">
									<Image style={{ background: '##efefef', borderRadius: '5px', padding: '2%', marginRight: '5%' }} width="35px" height="35px" src={INSTA_ICON} />
								</a>
								<a target="_blank" href="https://www.youtube.com/channel/UCFzASvNqPtJHs9vppbaszjw">
									<Image style={{ background: '##efefef', borderRadius: '5px', padding: '2%', marginRight: '5%' }} width="35px" height="35px" src={YOUTUBE_ICON} />
								</a>
								<a target="_blank" href="https://www.linkedin.com/company/smartmodo">
									<Image style={{ background: '##efefef', borderRadius: '5px', padding: '2%' }} width="35px" height="35px" src={LINKEDIN_ICON} />
								</a>
							</li>
						</ul>
						<ul>
							<li>Newsletter</li>
							<li style={{ lineHeight: 2 }}>
								{trans[lang].landingPage.getExp} <br /> {trans[lang].landingPage.toInbox}
							</li>
							<li className=" align-content-center align-items-center" style={{ display: 'flex' }}>
								<input onChange={(e) => setContactEmail(e.target.value)} placeholder="E-mail here" />
								<Row onClick={()=>{
									contactName = ' '
									contactLastName = ' '
									contactOrg = ' '
									contactPhone = ' '
									contactSubject = 
									sendContactForm(true)
								}} className="justify-content-center align-content-center" style={{ border: '1px solid #fff', borderRadius: '9px', height: '43px', background: '#009FE3', marginLeft: '-8px' }}>
									<Image width="40px" src={BXSSEND} style={{ height: '32px' }} />
								</Row>
							</li>
						</ul>
					</Row>
				</section>
			</Container>
		</>
	)
}

const mapStateToProps = (state) => ({
	lang: state.socialMediaR.lang,
})

export default connect(mapStateToProps, {})(LandingPage)
