import './Login.css'

import { Col, Form, Image, Modal, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { detectCurrency, getBillingPlan, getPaymentHistory, getPricingInfos, saveInfos } from '../../redux/actions/billingActions'
import numbro from 'numbro'
import { IoIosClose } from 'react-icons/io'
import Navbar from '../Components/Navbar'
import OwnerCheckbox from '../Home/Pages/OwnerCheckbox'
import Swal from 'sweetalert2'
import axios from 'axios'
import { host } from '../../config'
import moment from 'moment'
// Translations
import { trans } from '../../Translations'
var sha1 = require('sha1')
const INSTA_ICON = require('../../assets/images/home/slider-instagram.svg')
const FB_ICON = require('../../assets/images/home/slider-facebook.svg')
const USER_ICON = require('../../assets/images/login/price-user.svg')
const CHECKBOX_ICON = require('../../assets/images/login/price-checkbox.svg')

const guid = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}
	let id = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
	console.log('orderID generated ...', id)
	// setSignatureId(id);
	return id
}
let orderID = guid()
function Pricing(props) {
	const userData = JSON.parse(localStorage.getItem('userData'))
	const billingPlan = useSelector((state) => state.billingR.billingPlan)
	console.log('billingPlan', billingPlan)
	console.log('userData', userData)
	const [planShow, setPlanShow] = React.useState(false)
	const [tauxConversion, setTauxConversion] = useState()

	let {
		lang,
		pricingInfos,
		currency,

		// Functions
		detectCurrency,
		getPricingInfos,
		getPaymentHistory,
		getBillingPlan,
	} = props

	// Change Prod :
	const paramGpg = {
		prod: { numSite: 'MAR826', passSig: 'el@miR06', password: '7d3c7c976120bf6ab84a31e39308df37', vad: '657700003', url: 'https://www.gpgcheckout.com/Paiement/Validation_paiement.php' },
		preprod: { numSite: 'MAR868', passSig: 'bd$inC96', password: '6677f06ff9439a26d5dd81d49931662f', vad: '258500003', url: 'https://preprod.gpgcheckout.com/Paiement_test/Validation_paiement.php' },
	}
	const [curProd, setcurProd] = useState('prod')
	// Popup Config :
	const popup = Swal.mixin({
		customClass: {
			confirmButton: 'wizard-pages-active-btn py-2 px-3',
		},
		buttonsStyling: false,
	})

	const allMenuItems = [
		{ title: trans[lang].navbar.contactUs, route: 'contact-link' },
		{ title: trans[lang].navbar.howToUseIt, route: 'how-to-use-it-home' },
		{ title: trans[lang].navbar.pricing, route: 'pricing' },
	]

	const [showContact, setShowContact] = useState(false)
	const [contactName, setContactName] = useState('')
	const [contactLastName, setContactLastName] = useState('')
	const [contactOrg, setContactOrg] = useState('')
	const [contactEmail, setContactEmail] = useState('')
	const [contactPhone, setContactPhone] = useState('')
	const [contactSubject, setContactSubject] = useState('')
	const [contactMsg, setContactMsg] = useState('')
	const [checkboxPlan, setCheckboxPlan] = useState('')
	let [checkboxPeriod, setCheckboxPeriod] = useState('')
	const [choosenOffer, setChoosenOffer] = useState([])
	const [discount, setDiscount] = useState({})
	let [firstName, setFirstName] = useState('')
	let [lastName, setLastName] = useState('')
	let [email, setEmail] = useState('')
	let [adress, setAdress] = useState('')
	const [confirm, setConfirm] = useState(true)
	const dispatch = useDispatch()

	// const [activeLink, setActiveLink] = useState("Overview");
	const checkUserOffer = (packName, type) => {
		if (type === 'col') {
			// Column
			if (packName === 'Free Trial') {
				return 'price-colone-grise'
			}
			if (billingPlan?.name === packName) {
				return 'price-colone-grise'
			} else {
				return 'price-colone'
			}
		} else {
			// Button
			if (packName === 'Free Trial') {
				return 'price-button-grise m-auto'
			}
			if (billingPlan?.name === packName) {
				return 'price-button-grise m-auto'
			} else {
				return 'price-button m-auto'
			}
		}
	}
	const conversionCurrency = async ({ from, to }) => {
		const conv = await axios(`https://free.currconv.com/api/v7/convert?q=${from}_${to}&compact=ultra&apiKey=678de9206f8e50ff6dfb`)
		//console.log(conv)
		setTauxConversion(conv?.data[`${from}_${to}`])
	}
	const isValidEmail = (email) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	const sendContactForm = () => {
		if (contactName === '') {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validNameErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactLastName === '') {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validLastNameErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactOrg === '') {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validOrgErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (!isValidEmail(contactEmail)) {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validEmailErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactPhone === '') {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validPhoneErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactSubject === '') {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validSubjectErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else if (contactMsg === '') {
			popup.fire({
				icon: 'error',
				title: trans[lang].landingPage.validMsgErr,
				confirmButtonText: trans[lang].landingPage.retry,
			})
		} else {
			// SEND THE CONTACT FORM
			let objectToSend = {
				name: contactName,
				last_name: contactLastName,
				org: contactOrg,
				email: contactEmail,
				phone: contactPhone,
				subject: contactSubject,
				msg: contactMsg,
				status: false,
			}
			// console.log("im sending to nour", objectToSend);
			axios
				.post(host + `/api/v1/contact/addContact`, objectToSend)
				.then((res) => {
					console.log('/api/v1/contact/addContact APi Response =>', res.data.data)
					popup.fire({
						icon: 'success',
						title: 'Sent succesfully !',
						confirmButtonText: 'Thanks',
					})
					setShowContact(false)
				})
				.catch((err) => {
					console.log('/api/v1/contact/addContact APi post Api error  =>', err)
					popup.fire({
						icon: 'error',
						title: 'Connexion error please try again !',
						confirmButtonText: 'Okay',
					})
				})
		}
	}
	const calculateAmount = (offer, discount, period) => {
		let price = { priceTnd: '', priceSecond: '' }
		console.log('offer', offer)
		if (period === '' || period === 1 || period === undefined) {
			console.log('Returning Amount', parseFloat(offer[0]?.payement * 1000))
			price.priceTnd = parseFloat(offer[0]?.payement * 1000)
			price.priceSecondEur = parseFloat(offer[0]?.payement_fr * 100)
			price.priceSecondUsd = parseFloat(offer[0]?.payment_do * 100)
		} else if (period !== 1 && period !== undefined && discount !== {} && discount.payement !== undefined) {
			console.log('Returning Amount', parseFloat(discount.payement * 1000))
			console.log('discount', discount)
			price.priceTnd = parseFloat(discount.payement * 1000)
			price.priceSecondEur = parseFloat(discount?.payement_fr * 100)
			price.priceSecondUsd = parseFloat(discount?.payment_do * 100)
		}
		return price
	}
	const getAmount = (offer, discount, period) => {
		console.log('Discount is', discount)
		console.log('Offer payement is', offer[0]?.payement)
		console.log('Period', period)
		console.log(calculateAmount(offer, discount, period))
		let amount = { amountTnd: '', amountSecond: '' }
		switch (currency?.id) {
			case 'TND':
				amount.amountTnd = calculateAmount(offer, discount, period)?.priceTnd
				amount.amountSecond = ''
				break
			case 'EUR':
				amount.amountTnd = calculateAmount(offer, discount, period)?.priceSecondEur * tauxConversion * 10
				amount.amountSecond = calculateAmount(offer, discount, period)?.priceSecondEur
				break
			case 'USD':
				amount.amountTnd = calculateAmount(offer, discount, period)?.priceSecondUsd * tauxConversion * 10
				amount.amountSecond = calculateAmount(offer, discount, period)?.priceSecondUsd
				break
			default:
				break
		}
		console.log('amount', amount)

		return amount
	}
	const handleChange = (key) => (e) => {
		switch (key) {
			case 'lastName':
				lastName = e.target.value
				setLastName(e.target.value)
				break
			case 'firstName':
				firstName = e.target.value
				setFirstName(e.target.value)
				break
			case 'email':
				email = e.target.value
				setEmail(e.target.value)
				break
			case 'adress':
				adress = e.target.value
				setAdress(e.target.value)
				break

			default:
				break
		}
		if (firstName != '' && lastName != '' && email != '' && adress != '' && checkboxPeriod != '') setConfirm(false)
		else setConfirm(true)
		console.log({ key, val: e.target.value })
	}
	const handleDiscount = (offer, period) => {
		let discounts = {
			/* payement_fr: 0, payment_do: 0, payement: 0 */
		}

		// console.log("Discount this =>", offer, " On This ", discounts);

		if (period === 1) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			setDiscount({})
		} else if (period === 6) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			discounts.payement_fr = offer.payement_fr * 6 - offer.payement_fr * 6 * 0.05
			discounts.payment_do = offer.payment_do * 6 - offer.payment_do * 6 * 0.05
			discounts.payement = offer.payement * 6 - offer.payement * 6 * 0.05
			setDiscount(discounts)
		} else if (period === 3) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			discounts.payement_fr = offer.payement_fr * 6 - offer.payement_fr * 3 * 0.05
			discounts.payment_do = offer.payment_do * 3 - offer.payment_do * 3 * 0.05
			discounts.payement = offer.payement * 3 - offer.payement * 3 * 0.05
			setDiscount(discounts)
		} else if (period === 12) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			discounts.payement_fr = offer.payement_fr * 12 - offer.payement_fr * 12 * 0.1
			discounts.payment_do = offer.payment_do * 12 - offer.payment_do * 12 * 0.1
			discounts.payement = offer.payement * 12 - offer.payement * 12 * 0.1
			setDiscount(discounts)
		}
	}
	const renderPlanModal = () => {
		// let orderID = guid();
		console.log(getAmount(choosenOffer, discount, checkboxPeriod))
		console.log('SIgn', sha1('MAR868' + 'bd$inC96' + orderID + getAmount(choosenOffer, discount, checkboxPeriod)?.amountTnd + currency.id))
		return (
			<Modal show={planShow} size={'lg'} centered>
				<Modal.Body className="p-0">
					<form onSubmit={handleSubmit} action={paramGpg[curProd]?.url} method="post">
						{/* <div> */}
						<input name="NumSite" id="NumSite" value={paramGpg[curProd]?.numSite} hidden />
						<input name="Password" id="Password" value={paramGpg[curProd]?.password} hidden />
						<input name="orderID" id="orderID" value={orderID} hidden />
						<input name="Langue" id="Langue" value={lang} hidden />
						<input name="CustTel" id="CustTel" className="formulaire-pricing-input" hidden value={'55111222'} /*onChange={(e) => setPhone(e.target.value)}*/ />

						<input name="Currency" id="Currency" value={currency.id} hidden />

						<input name="Amount" id="Amount" value={parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountTnd)} hidden />
						<input name="AmountSecond" id="AmountSecond" value={parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountSecond)} hidden />
						<input name="TauxConversion" id="TauxConversion" value={tauxConversion} hidden />
						{/* {currency.id === "EUR" 
						? 
						pricing.payement_fr 
						: 
						currency.id === "USD" 
						? 
						pricing.payment_do 
						: 
						pricing.payement}    
						}
						<input name="AmountSecond" id="AmountSecond" value="40000" hidden /> */}

						<input name="PayementType" id="PayementType" value="1" hidden />
						<input name="orderProducts" id="orderProducts" value={choosenOffer[0]?.name} hidden />
						<input name="signature" id="signature" value={sha1(paramGpg[curProd]?.numSite + paramGpg[curProd]?.passSig + orderID + parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountTnd) + currency.id)} hidden />
						<input name="vad" id="vad" value={paramGpg[curProd]?.vad} hidden />
						<input name="Terminal" id="Terminal" value={currency?.terminal} hidden />
						{/* </div> */}
						<Row className="formulaire-pricing justify-content-center d-flex w-100 h-100">
							<Col lg={12} className="d-flex mb-1">
								<IoIosClose
									color={'#B4B4B4'}
									size={30}
									className="ml-auto"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setPlanShow(false)
										checkboxPeriod = ''
										setCheckboxPeriod('')
										setChoosenOffer([])
										setDiscount({})
									}}
								/>
							</Col>

							<Col lg={12} className="d-flex">
								<h1 className="mx-auto mb-5">{trans[lang].formPayment.purchaseOrder}</h1>
							</Col>

							<Col lg={5} className="d-flex flex-column mb-2">
								<label className="">{trans[lang].formPayment.firstName}*</label>
								<input autoComplete="off" name="CustLastName" id="CustLastName" className="formulaire-pricing-input" value={lastName} onChange={handleChange('lastName')} />
							</Col>

							<Col lg={5} className="d-flex flex-column mb-2">
								<label className="">{trans[lang].formPayment.lastName}*</label>
								<input autoComplete="off" name="CustFirstName" id="CustFirstName" className="formulaire-pricing-input" value={firstName} onChange={handleChange('firstName')} />
							</Col>

							<Col lg={5} className="d-flex flex-column">
								<label className="">{trans[lang].formPayment.email}*</label>
								<input autoComplete="off" name="EMAIL" id="EMAIL" className="formulaire-pricing-input" value={email} onChange={handleChange('email')} />
							</Col>

							<Col lg={5} className="d-flex flex-column">
								<label className="">{trans[lang].formPayment.address}*</label>
								<input autoComplete="off" name="CustAddress" id="CustAddress" className="formulaire-pricing-input" value={adress} onChange={handleChange('adress')} />
							</Col>

							{/* <Col lg={5} className="d-flex flex-column" >
						<label className="">Phone number*</label>
						<input autoComplete="off" name="CustTel" id="CustTel" className="formulaire-pricing-input" hidden value={phone} onChange={(e) => setPhone(e.target.value)}/>
					</Col>                                                           
				 */}
							<Col lg={10} className="d-flex flex-column">
								<p>*{trans[lang].formPayment.privideInform}</p>
								<hr />
							</Col>
							<Col lg={10} className="d-flex flex-column">
								<h2>{trans[lang].formPayment.selectPlan}</h2>
							</Col>

							{pricingInfos.map(
								(pricing, index) =>
									pricing.name !== 'Free Trial' && (
										<Col lg={5} key={index * Math.random() * 2}>
											<div
												className={checkboxPlan === pricing.name ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
												onClick={() => {
													setChoosenOffer([pricing])
													setCheckboxPlan(pricing.name)
													checkboxPeriod = ''
													setCheckboxPeriod('')
													setDiscount({})
												}}
											>
												<OwnerCheckbox checked={checkboxPlan === pricing.name ? true : false} color={true} />
												{/* <p className="my-auto ml-3">{lang === 'fr' ? pricing.name_fr : pricing.name}</p> */}
												<p className="my-auto ml-3">{trans[lang].billingPlan[pricing.name]}</p>
											</div>
										</Col>
									)
							)}

							<Col lg={5}></Col>

							<Col lg={10} className="d-flex flex-column">
								<h2 className="mt-4">{trans[lang].formPayment.selectPeriod}</h2>
							</Col>

							<Col lg={5} className="">
								<div
									className={checkboxPeriod === 1 ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
									onClick={() => {
										handleDiscount(choosenOffer[0], 1)
									}}
								>
									<OwnerCheckbox checked={checkboxPeriod === 1 ? true : false} color={true} />
									<p className="my-auto ml-3">{trans[lang].formPayment.oneMonth}</p>
								</div>
							</Col>
							<Col lg={5} className="">
								<div
									className={checkboxPeriod === 6 ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
									onClick={() => {
										handleDiscount(choosenOffer[0], 6)
									}}
								>
									<OwnerCheckbox checked={checkboxPeriod === 6 ? true : false} color={true} />
									<p className="my-auto ml-3">6 {trans[lang].formPayment.months}</p>{' '}
									<p className="my-auto ml-1" style={{ fontSize: '10px' }}>
										{trans[lang].formPayment.get5})
									</p>
								</div>
							</Col>

							<Col lg={5} className="">
								<div
									className={checkboxPeriod === 12 ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
									onClick={() => {
										handleDiscount(choosenOffer[0], 12)
									}}
								>
									<OwnerCheckbox checked={checkboxPeriod === 12 ? true : false} color={true} />
									<p className="my-auto ml-3">12 {trans[lang].formPayment.months}</p>
									<p className="my-auto ml-1" style={{ fontSize: '10px' }}>
										({trans[lang].formPayment.get10})
									</p>
								</div>
							</Col>

							<Col lg={10} className="d-flex mt-4 justify-content-center">
								{Object.keys(discount).length ? (
									<>
										<p id="total-amount">{trans[lang].formPayment.totalAmount} :</p>
										<p id="price">
											{currency.id === 'EUR'
												? numbro(parseFloat(discount.payement_fr)).format({
														mantissa: 2,
												  })
												: currency.id === 'USD'
												? numbro(parseFloat(discount.payment_do)).format({
														mantissa: 2,
												  })
												: numbro(parseFloat(discount.payement)).format({
														mantissa: 2,
												  })}{' '}
											{currency.value}
										</p>
										<p id="tax">{trans[lang].formPayment.taxIncluded}</p>
									</>
								) : (
									<>
										<p id="total-amount">{trans[lang].formPayment.totalAmount} :</p>
										<p id="price">
											{currency.id === 'EUR'
												? numbro(parseFloat(choosenOffer[0]?.payement_fr)).format({
														mantissa: 2,
												  })
												: currency.id === 'USD'
												? numbro(parseFloat(choosenOffer[0]?.payment_do)).format({
														mantissa: 2,
												  })
												: numbro(parseFloat(choosenOffer[0]?.payement)).format({
														mantissa: 2,
												  })}{' '}
											{currency.value}
										</p>
										<p id="tax">{trans[lang].formPayment.taxIncluded}</p>
									</>
								)}
							</Col>

							<Col lg={3} className="d-flex mt-4">
								{console.log('confirmmmmmmm', confirm)}
								<button className="price-button" type="submit" disabled={confirm}>
								{ trans[lang].formPayment.confirm}
								</button>
							</Col>
						</Row>
					</form>
				</Modal.Body>
			</Modal>
		)
	}
	const handleSubmit = async () => {
		//  let orderID = guid();

		const Agents = checkboxPlan === 'Free Trial' ? pricingInfos[0].number_agent : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].number_agent : checkboxPlan === 'Agencies' ? pricingInfos[2].number_agent : pricingInfos[3].number_agent
		const Pages = checkboxPlan === 'Free Trial' ? pricingInfos[0].number_pages : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].number_pages : checkboxPlan === 'Agencies' ? pricingInfos[2].number_pages : pricingInfos[3].number_pages
		const autoReply =
			checkboxPlan === 'Free Trial'
				? pricingInfos[0].number_autoReply
				: checkboxPlan === 'SME/Artisans'
				? pricingInfos[1].number_autoReply
				: checkboxPlan === 'Agencies'
				? pricingInfos[2].number_autoReply
				: pricingInfos[3].number_autoReply
		const numberUser = checkboxPlan === 'Free Trial' ? pricingInfos[0].number_user : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].number_user : checkboxPlan === 'Agencies' ? pricingInfos[2].number_user : pricingInfos[3].number_user
		const Payment = checkboxPlan === 'Free Trial' ? pricingInfos[0].payement : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].payement : checkboxPlan === 'Agencies' ? pricingInfos[2].payement : pricingInfos[3].payement

		let totalPrice
		switch (currency.id) {
			case 'EUR':
				totalPrice = Object.keys(discount).length ? discount.payement_fr : choosenOffer[0]?.payement_fr

				break
			case 'USD':
				totalPrice = Object.keys(discount).length ? discount.payment_do : choosenOffer[0]?.payment_do
				break

			default:
				totalPrice = Object.keys(discount).length ? discount.payement : choosenOffer[0]?.payement
				break
		}
		totalPrice = numbro(parseFloat(totalPrice)).format({
			mantissa: 2,
		})
		const data = {
			last_name: lastName,
			user_name: firstName,
			email: email,
			adresse: adress,
			payedBy: `${firstName} ${lastName}`,
			idPack: orderID,
			name: checkboxPlan,
			delai: checkboxPeriod,
			number_agent: Agents,
			number_pages: Pages,
			number_autoReply: autoReply,
			number_user: numberUser,
			payment: totalPrice,
		}
		console.log('my data is: ', JSON.stringify(data))
		const idFacebook = localStorage.getItem('authtoken')
		await dispatch(saveInfos(data, idFacebook))
	}
	// Rendering Methods
	const renderContactModal = () => (
		<Modal show={showContact} size={'lg'} centered>
			<Modal.Body style={{ background: '#F5F5F5', borderRadius: '10px' }}>
				<Col lg={12}>
					<Row>
						<Col lg={12} className="d-flex mb-1">
							<IoIosClose
								color={'#B4B4B4'}
								size={30}
								className="ml-auto"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setShowContact(false)
								}}
							/>
						</Col>

						<Col lg={12} className="d-flex mb-2">
							<p className="m-auto contact-us-title">{trans[lang].landingPage.contactUs}</p>
						</Col>

						<Col lg={12}>
							<Form>
								<Form.Row className="mb-3">
									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.firstName} *</Form.Label>
										<Form.Control onChange={(e) => setContactName(e.target.value)} id="contact-form-input" />
									</Col>

									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.lastName} *</Form.Label>
										<Form.Control onChange={(e) => setContactLastName(e.target.value)} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="mb-3">
									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.org} *</Form.Label>
										<Form.Control onChange={(e) => setContactOrg(e.target.value)} id="contact-form-input" />
									</Col>

									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.email} *</Form.Label>
										<Form.Control onChange={(e) => setContactEmail(e.target.value)} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="mb-3">
									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.phone}</Form.Label>
										<Form.Control type="number" onChange={(e) => setContactPhone(e.target.value)} id="contact-form-input" />
									</Col>

									<Col lg={6}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.subject}</Form.Label>
										<Form.Control onChange={(e) => setContactSubject(e.target.value)} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="mb-3">
									<Col lg={12}>
										<Form.Label id="contact-form-label">{trans[lang].landingPage.message}</Form.Label>
										<Form.Control onChange={(e) => setContactMsg(e.target.value)} as="textarea" rows={3} id="contact-form-input" />
									</Col>
								</Form.Row>

								<Form.Row className="justify-content-center mb-2">
									<div className="contact-form-button py-2 text-center" onClick={() => sendContactForm()}>
										{trans[lang].landingPage.send}
									</div>
								</Form.Row>
							</Form>
						</Col>
					</Row>
				</Col>
			</Modal.Body>
		</Modal>
	)

	const checkPriceColumn = (idCur, euro, usd, tnd) => {
		if (idCur === 'EUR') {
			let currStringLength = euro.toString().length
			return currStringLength < 3 ? 2 : currStringLength < 4 ? 5 : 6
		} else if (idCur === 'USD') {
			let currStringLength = usd.toString().length
			return currStringLength < 3 ? 2 : currStringLength < 4 ? 5 : 6
		} else {
			let currStringLength = tnd.toString().length
			return currStringLength < 3 ? 2 : currStringLength < 4 ? 5 : 6
		}
	}
	const fireModal = (pricing) => {
		setChoosenOffer([pricing])
		setCheckboxPlan(pricing.name)
		setPlanShow(true)
	}
	useEffect(() => {
		detectCurrency().then(() => {
			getBillingPlan().then(() => {
				console.log(billingPlan)

				/* if (billingPlan) {
					var currentDate = moment()
					var date = moment(billingPlan?.expyriedate)
					var day_expire = date.diff(currentDate, 'days')
					console.log('date', day_expire)
					if (billingPlan && day_expire <= 3)
						Swal.fire({
							icon: 'error',
							title: `Votre plan va expirer le ${billingPlan.expyriedate}`,
							//text: 'Something went wrong!',
							//footer: '<a href>Why do I have this issue?</a>',
						})
				}
 */
				getPricingInfos().then(() => {
					getPaymentHistory()
				})
			})
		})
	}, [])
	useEffect(() => {
		console.log('checkboxPeriod', checkboxPeriod)
		if (firstName != '' && lastName != '' && email != '' && adress != '' && checkboxPeriod != '') setConfirm(false)
		else setConfirm(true)
	}, [checkboxPeriod])
	useEffect(() => {
		conversionCurrency({ from: currency?.id, to: 'TND' })
	}, [currency])
	/* useEffect(() => {
		console.log({ firstName, lastName, email, adress })
		if (firstName != '' && lastName != '' && email != '' && adress != '' && checkboxPeriod != '') setConfirm(false)
		else setConfirm(true)
	}, [firstName, lastName, email, adress]) */
	return (
		<>
			{showContact && renderContactModal()}

			<Navbar menuItems={allMenuItems} loginBtn={true} setShowContact={setShowContact} />

			<div className="page-price" style={{ marginTop: '60px' }}>
				<Row className="justify-content-center">
					<Col lg={12} className="d-flex mt-3">
						<h1 className="price-titre mx-auto mt-5">{trans[lang].pricing.readyTo}</h1>
					</Col>
					<Col lg={12} className="d-flex">
						<h1 className="price-sous-titre m-auto">{trans[lang].pricing.getFree}</h1>
					</Col>
					{/* pricing bloc */}
					<Col lg={10} className="d-flex p-0 mt-5">
						<Row className='list-plan'>
							{pricingInfos.length > 0 &&
								currency.id.length > 0 &&
								pricingInfos.map((pricing, index) => (
									<Col lg={3} key={index + Math.random()} className="d-flex p-3">
										<Row>
											<Col
												lg={12}
												className="mt-2"
												style={{
													height: '60px',
												}}
											>
												<p className="price-titre-item mr-auto my-auto">{trans[lang].billingPlan[pricing.name]}</p>
												{pricing.company && <p className="price-sous-titre mr-auto my-auto">{lang === 'fr' ? pricing.company_fr : pricing.company}</p>}
											</Col>

											<div className="py-3 px-4">
												<Row className={checkUserOffer(pricing.name, 'col')}>
													<Col lg={12} className="d-flex">
														<Row className="justify-content-center w-100">
															<Col xs={2} className="d-flex p-0">
																<p
																	className="price-price mx-auto mb-auto ml-auto"
																	style={{
																		fontSize: '19px',
																		color: '#E5007D',
																	}}
																>
																	{currency.value}
																</p>
															</Col>
															<Col lg={() => checkPriceColumn(currency.id, pricing.payement_fr, pricing.payment_do, pricing.payement)} className="d-flex p-0">
																<p
																	className="price-price my-auto ml-auto"
																	style={{
																		color: '#E5007D',
																	}}
																>
																	{currency.id === 'EUR' ? pricing.payement_fr : currency.id === 'USD' ? pricing.payment_do : pricing.payement}
																</p>
															</Col>
															<Col xs={3} className="d-flex p-0">
																<p
																	className="price-price my-auto mr-auto"
																	style={{
																		fontSize: '14px',
																	}}
																>
																	/{trans[lang].pricing.month}
																</p>
															</Col>
														</Row>
													</Col>

													<Col lg={12} className="d-flex">
														<p className="price-sous-titre-price mx-auto mt-2">{trans[lang].pricing.supportedNetwork}</p>
													</Col>

													<Col
														lg={12}
														className="d-flex"
														style={{
															justifyContent: 'center',
														}}
													>
														<Image
															src={FB_ICON}
															className=" m-1 "
															style={{
																width: '7%',
															}}
														/>
														<Image
															src={INSTA_ICON}
															className=" m-1"
															style={{
																width: '7%',
															}}
														/>
													</Col>

													<Col lg={12} className="d-flex price-sous-titre-price mt-3 mb-4 p-0">
														<Image src={USER_ICON} className="mr-3 price-icon-size" />
														{lang === 'fr' ? pricing.number_pages_fr : pricing.number_pages}, {lang === 'fr' ? pricing.number_user_fr : pricing.number_user}
													</Col>

													<Row
														className="mx-0"
														style={{
															height: '230px',
															alignContent: 'start',
														}}
													>
														{pricing.number_agent && (
															<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.number_agent_fr : pricing.number_agent}
															</Col>
														)}

														{pricing.history && (
															<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.history_fr : pricing.history}
															</Col>
														)}

														{pricing.performance && (
															<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.performance_fr : pricing.performance}
															</Col>
														)}

														{pricing.training && (
															<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.training_fr : pricing.training}
															</Col>
														)}

														{pricing.support && (
															<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.support_fr : pricing.support}
															</Col>
														)}

														{pricing.number_autoReply && (
															<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.number_autoReply_fr : pricing.number_autoReply}
															</Col>
														)}
													</Row>

													<Col lg={12} className="d-flex">
														<button
															className={checkUserOffer(pricing.name, 'btn')}
															onClick={() => {
																checkUserOffer(pricing.name, 'col') === 'price-colone' && fireModal(pricing)
															}}
														>
															{checkUserOffer(pricing.name, 'col') === 'price-colone-grise' ? trans[lang].pricing.actualPlan : trans[lang].pricing.choosePlan}
														</button>
													</Col>
												</Row>
											</div>
										</Row>
									</Col>
								))}
						</Row>
					</Col>
					{/*END pricing bloc */}
					<Row className="mx-0 w-100 flex-column" style={{ alignContent: 'center' }}>
						<Col lg={4} className="d-flex mb-1">
							<p className="price-titre my-auto mr-auto">{trans[lang].pricing.noSubs}</p>
						</Col>
						<Col lg={4} className="d-flex mb-5">
							<p className="price-credit-card my-auto mr-auto" style={{ lineHeight: '2', fontSize: '14' }}>
								{trans[lang].pricing.buyOne}
							</p>
						</Col>
					</Row>
				</Row>
			</div>
			{renderPlanModal()}
		</>
	)
}

const mapStateToProps = (state) => ({
	lang: state.socialMediaR.lang,
	pricingInfos: state.billingR.pricingInfos,
	currency: state.billingR.currency,
})

export default connect(mapStateToProps, {
	getPricingInfos,
	detectCurrency,
	getBillingPlan,
	detectCurrency,
	getPaymentHistory,
})(Pricing)
