import React from 'react'
import Fb from './Fb'

class CustomerChat extends React.PureComponent {
	componentDidMount() {
		this.timeout = setTimeout(() => {
			Fb((FB) => this.timeout && FB.XFBML.parse())
			Fb((FB) => FB.CustomerChat.show(true))
		}, 2000)
	}

	componentWillUnmount() {
		clearTimeout(this.timeout)
		delete this.timeout
	}

	render() {
		return <div className="fb-customerchat" attribution="install_email" attribution_version="setup_tool" page_id="110866890814039" logged_in_greeting="Hi! How can we help you?" logged_out_greeting="Hi! How can we help you?"></div>
	}
}

export default CustomerChat
