import React, { useEffect, useState } from 'react'
import './Components.css'

import Facebook from '../Login/Facebook'

import { Row, Col, Nav, Image,Modal } from 'react-bootstrap'
import axios from 'axios'
import { IoIosClose } from 'react-icons/io'
import { connect } from 'react-redux'
import { setLang } from '../../redux/actions/socialMediaActions'

import { Redirect, withRouter, Link,useHistory } from 'react-router-dom'

// Translations
import { trans } from '../../Translations'

import { host, Desktop, Tablet, Mobile } from '../../config'

import { IoIosArrowDown } from 'react-icons/io'
import { AiOutlineMenu, AiOutlineClose, AiFillHome } from 'react-icons/ai'
import { ReactComponent as LogoutSvg } from '../../assets/images/login/logout.svg'
import { ReactComponent as TutoSvg} from '../../assets/images/home/tutoriel.svg'
import ReactTooltip from 'react-tooltip'

const LOGO = require('../../assets/images/home/logo.svg')

function Navbar(props) {
	let {
		lang,
		location,

		setLang,
	} = props

	const [userDataFromLogin, setUserDataFromLogin] = useState(null)
	/* const [redirect, setRedirect] = useState(false)
	const [redirectDocs, setRedirectDocs] = useState(false)
	const [redirectPricing, setRedirectPricing] = useState(false) */
	const [mobileMenu, setMobileMenu] = useState(false)
	const [showPopup, setShowPopup] = useState(false)
	const history = useHistory()
	const ShowPopup = ()=>(
		<Modal className='show-video' show={showPopup} size={'lg'} centered>
			<Modal.Body style={{ background: '#F7F7F7', borderRadius: '20px' }}>
				<Col lg={12} sm={12}>
					<Row>
						<Col lg={12} sm={12} className="d-flex mb-1" >
							<IoIosClose
								color={'#B4B4B4'}
								size={30}
								className="ml-auto"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									setShowPopup(false)
								}}
							/>
						</Col>
						<Col lg={12} sm={12} className="d-flex mb-2">
						<iframe className='col-lg-12' src={trans[lang].docs.overview1.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
						</Col>
					</Row>
				</Col>
			</Modal.Body>
		</Modal>
	)
	useEffect(() => {
		let userDataFromLocal = JSON.parse(localStorage.getItem('userData'))
		setUserDataFromLogin(userDataFromLocal)
	}, [])

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault()
				onClick(e)
			}}
		>
			<IoIosArrowDown color={'#818E94'} size={'17'} style={{ marginBottom: '50px!important' }} className="my-auto ml-2 navbar-arrow-icon" />
		</a>
	))

	const Logout = async () => {
		setUserDataFromLogin(null)

		await axios
			.post(host + '/api/v1/auth/logout', null, {
				headers: {
					authorization: 'Bearer ' + localStorage.getItem('authtoken'),
				},
			})
			.then(async (res) => {
				console.log('Rep logout', res.data)
				await localStorage.clear()
				history.push('/')
				//setRedirect(true)
			})
			.catch((err) => {
				console.log('Cannot logout', err)
			})
	}

	function myFunction() {
		document.getElementById('myDropdown').classList.toggle('show')
	}

	// Close the dropdown if the user clicks outside of it
	window.onclick = function (event) {
		if (!event.target.matches('.navbar-menu-title')) {
			var dropdowns = document.getElementsByClassName('dropdown-content')
			var i
			for (i = 0; i < dropdowns.length; i++) {
				var openDropdown = dropdowns[i]
				if (openDropdown.classList.contains('show')) {
					openDropdown.classList.remove('show')
				}
			}
		}
	}

	return (
		<>
			{/* For Redirection don't touch */}
		{/* 	{redirect && <Redirect to={'/'} />}
			{redirectDocs && <Redirect to={'/how-to-use-it'} />}
			{redirectPricing && <Redirect to={'/pricing'} />} */}

			<Desktop>
				<div style={{ display: 'flex', position: 'relative' }}>
					<div
						style={{
							boxShadow: 'rgb(0 159 227 / 10%) 0px 3px 6px' ,
							position: 'fixed',
							zIndex: '2',
							width: '100%',
							top: 0,
						}}
					>
					{/* 	{location.pathname === '/' && (
							<Row className="justify-content-center free-trial p-3">
								<Col lg={12} className="d-flex justify-content-center align-items-center">
									<div className="text-free-trial mr-5">{trans[lang].navbar.freeTrial}</div>
									<div className="d-flex">
										<Facebook type="signUp" content={trans[lang].navbar.signUp} />
									</div>
								</Col>
							</Row>
						)} */}
						<Nav
							activeKey="/home"
							onSelect={(e, selectedKey) => {
								selectedKey === 'contact-link' && props.setShowContact(true)
							}}
						>
							<Row className="justify-content-between m-auto w-100 navbar-menu-style py-2">
										<Link
											//className="m-auto"
											style={{
												marginLeft: '4%',
												width: '10%',
											}}
											to={location.pathname === '/how-to-use-it' || location.pathname === '/pricing' ? '/' : '#'}
										>
											<Image src={LOGO} className={'my-auto mr-auto'} style={{ maxWidth: '80px' }} />
										</Link>
										
										<Row className={'align-items-center justify-content-center'}>
										
										{userDataFromLogin && !props.loginBtn && <><TutoSvg
										data-for="tutoriel"
										data-tip={trans[lang].docs.overview1.desc}
											className="mr-5 align-self-center"
											style={{
												fill:'#4080ff',
												cursor:"pointer"
											}}
											 onClick={()=>setShowPopup(true)}
										/>
										<ReactTooltip
											id="tutoriel"
											textColor="#fff"
											backgroundColor="#009EE3"
										/>
										</>}
										
											{props.menuItems.length > 0 &&
												props.menuItems.map((menuItem, index) => (
													<Nav.Item key={index}>
														<Nav.Link
															className="navbar-menu-title"
															href={menuItem.route === 'what-is-modo' ? '#what-is-modo' : menuItem.route === 'contact-link' ? '#' :'#how-make'}
															onClick={() => {
																menuItem.route === 'contact-link'
																	? props.setShowContact(true)
																	: menuItem.route === 'how-to-use-it-home'
																	? history.push('/how-to-use-it')
																	: menuItem.route === 'pricing' && history.push('/pricing')
															}}
														>
															{menuItem.title}
														</Nav.Link>
													</Nav.Item>
												))}
										</Row>
										<div className="d-flex flex-wrap justify-content-center">
									{location.pathname === '/how-to-use-it' || location.pathname === '/pricing' ? (
										<>
											<div className="ml-auto my-auto mr-5">
												<Row>
													<Col lg={12} className="d-flex">
														<Row className="justify-content-center my-auto mx-1">
															<Col
																xs={6}
																style={{
																	fontSize: '12px',
																}}
																className={lang === 'fr' ? 'my-agents-msgs-matched-btn px-3 py-1' : 'my-agents-msgs-not-matched-btn px-3 py-1'}
																onClick={() => setLang('fr')}
															>
																FR
															</Col>
															<Col
																xs={6}
																style={{
																	fontSize: '12px',
																}}
																className={lang === 'en' ? 'my-agents-msgs-matched-btn px-3 py-1' : 'my-agents-msgs-not-matched-btn px-3 py-1'}
																onClick={() => setLang('en')}
															>
																EN
															</Col>
														</Row>
													</Col>
												</Row>
											</div>
											<Link className="my-auto mr-5" to={location.pathname === '/how-to-use-it' || location.pathname === '/pricing' ? '/' : '#'}>
												<AiFillHome color="#E5007D" size="30" className="ml-auto my-auto mr-3" />
											</Link>
										</>
									) : userDataFromLogin && !props.loginBtn ? (
										<>
											<div className="ml-auto mt-auto mr-5 d-flex">
												<div className="dropdown">
													<div>
														<Row>
															<Col lg={5} className="d-flex">
																<Row className="justify-content-center my-auto mx-1">
																	<Col
																		xs={6}
																		style={{
																			fontSize: '12px',
																		}}
																		className={lang === 'fr' ? 'my-agents-msgs-matched-btn px-3 py-1' : 'my-agents-msgs-not-matched-btn px-3 py-1'}
																		onClick={() => setLang('fr')}
																	>
																		FR
																	</Col>
																	<Col
																		xs={6}
																		style={{
																			fontSize: '12px',
																		}}
																		className={lang === 'en' ? 'my-agents-msgs-matched-btn px-3 py-1' : 'my-agents-msgs-not-matched-btn px-3 py-1'}
																		onClick={() => setLang('en')}
																	>
																		EN
																	</Col>
																</Row>
															</Col>

															<Col lg={7}>
																<Row className="mx-1">
																	<Col
																		xs={6}
																		/* className="d-flex" */
																	>
																		<Image
																			src={userDataFromLogin['user'].profilePictureUrl}
																			style={{
																				maxWidth: '50px',
																				borderRadius: '50%',
																			}}
																			className={'my-auto'}
																		/>
																	</Col>

																	<Col xs={6} className="d-flex navbar-menu-title">
																		<IoIosArrowDown className="navbar-menu-title navbar-arrow-icon" onClick={() => myFunction()} id="dropdown-custom-components" color={'#818E94'} size={'17'} />
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>

													<div id="myDropdown" className="dropdown-content">
														<div className="d-flex">
															<LogoutSvg
																className="m-auto"
																style={{
																	alignItems: 'flex-start',
																}}
															/>
															<a
																className="m-auto navbar-menu-title"
																onClick={() => Logout()}
																style={{
																	alignItems: 'flex-end',
																}}
															>
																{trans[lang].navbar.logout}
															</a>
														</div>
													</div>
												</div>
											</div>
										</>
									) : (
										props.loginBtn && (
											<>
												<div
													className="my-auto "
													style={
														{
															//padding: '.5rem 1rem',
														}
													}
												>
													<Row>
														<Col lg={12} className="d-flex">
															<Row className="justify-content-center my-auto mx-1">
																<Col
																	xs={6}
																	style={{
																		fontSize: '12px',
																	}}
																	className={lang === 'fr' ? 'my-agents-msgs-matched-btn px-3 py-1' : 'my-agents-msgs-not-matched-btn px-3 py-1'}
																	onClick={() => setLang('fr')}
																>
																	FR
																</Col>
																<Col
																	xs={6}
																	style={{
																		fontSize: '12px',
																	}}
																	className={lang === 'en' ? 'my-agents-msgs-matched-btn px-3 py-1' : 'my-agents-msgs-not-matched-btn px-3 py-1'}
																	onClick={() => setLang('en')}
																>
																	EN
																</Col>
															</Row>
														</Col>
													</Row>
												</div>
												<Nav.Item
													className="my-auto mr-2"
													style={{
														padding: '.5rem 1rem',
													}}
												>
													<Facebook  content={trans[lang].navbar.signUp} />
													<Facebook active content={trans[lang].navbar.login} />
												</Nav.Item>
											</>
										)
									)}
								</div>
							</Row>
						</Nav>
					</div>
				</div>
			</Desktop>

			<Mobile>
				<Row
					style={{
						height: 'auto',
						width: 'auto',
						background: '#fff',
						boxShadow: '0px 0px 6px -1px',
						position: 'relative',
						padding: '10px',
					}}
				>
					<Col xs={3} className="d-flex">
						<Image src={LOGO} className="mr-auto my-auto ml-3" style={{ maxWidth: '50px' }} />
					</Col>
					<Col xs={3}>
					{userDataFromLogin && !props.loginBtn && <TutoSvg
						data-for="tutoriel"
						data-tip={trans[lang].docs.overview1.desc}
							className="mr-5 align-self-center"
							style={{
								fill:'#4080ff',
								cursor:"pointer"
							}}
								onClick={()=>setShowPopup(true)}
						/>}
						<ReactTooltip
							id="tutoriel"
							textColor="#fff"
							backgroundColor="#009EE3"
						/>
					</Col>
					<Col xs={6} className="d-flex">
						{mobileMenu ? (
							<AiOutlineClose color="#E5007D" size="25" className="ml-auto my-auto mr-3" onClick={() => setMobileMenu(false)} />
						) : (
							<AiOutlineMenu color="#E5007D" size="25" className="ml-auto my-auto mr-3" onClick={() => setMobileMenu(true)} />
						)}
						<Link className="my-auto mr-1" to={location.pathname === '/how-to-use-it' || location.pathname === '/pricing' ? '/' : '#'}>
												<AiFillHome color="#E5007D" size="30" className="ml-auto my-auto" />
											</Link>
					</Col>

					{mobileMenu && (
						<Col xs={12}>
							<Row className={'mt-3 flex-column'}>
								<hr
									style={{
										border: '1px solid #EBEDF0',
										opacity: '1',
										padding: '0',
										margin: '5px auto',
										width: '100%',
									}}
								/>
								{props.menuItems.length > 0 &&
									props.menuItems.map((menuItem, index) => (
										<Nav.Item key={index} className="ml-3">
											<Nav.Link
												className="navbar-menu-title my-1"
												href={menuItem.route === 'what-is-modo' ? '#what-is-modo' : menuItem.route === 'contact-link' && '#'}
												onClick={() => {
													menuItem.route === 'contact-link'
														? props.setShowContact(true)
														: menuItem.route === 'how-to-use-it-home'
														? history.push('/how-to-use-it')
														: menuItem.route === 'pricing' && history.push('/pricing')
												}}
											>
												{menuItem.title}
											</Nav.Link>
										</Nav.Item>
									))}
								<hr
									style={{
										border: '1px solid #EBEDF0',
										opacity: '1',
										padding: '0',
										margin: '5px auto',
										width: '100%',
									}}
								/>
							</Row>
							{props.loginBtn ? (
								<div className="d-flex">
									<Nav.Item className="mx-auto mt-5">
										<Facebook type="nav" content="Login" />
									</Nav.Item>
								</div>
							):
								<div className="d-flex justify-center">
									<Nav.Item className="mx-auto mt-5" style={{"backgroundColor":"#e5007d","padding":"5px","borderRadius":"4px","color":"#fff","fontFamily":"'Poppins Medium'","fontSize":"14px","cursor":"pointer","textAlign":"center","alignSelf":"center","transitionDuration":"400ms"}}>
									<LogoutSvg
									className='logout-mobile'
									/>
									<a
										className="ml-2 navbar-menu-title"
										onClick={() => Logout()}
										style={{color:'#fff'}}
										
									>
										{trans[lang].navbar.logout}
									</a>
										</Nav.Item>
								</div>
							}

							<Row className="justify-content-center  w-100 mx-0 my-5 ">
								<Col xs={10} className="navbar-menu-title p-0 mb-2">
									Language
								</Col>
								<Col xs={5} className={lang === 'fr' ? 'my-agents-msgs-matched-btn' : 'my-agents-msgs-not-matched-btn'} onClick={() => setLang('fr')}>
									FR
								</Col>
								<Col xs={5} className={lang === 'en' ? 'my-agents-msgs-matched-btn' : 'my-agents-msgs-not-matched-btn'} onClick={() => setLang('en')}>
									EN
								</Col>
							</Row>
							<div className="d-flex">
								
							</div>
						</Col>
					)}
				</Row>
			</Mobile>
			<ShowPopup/>
							
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		lang: state.socialMediaR.lang,
	}
}

export default withRouter(connect(mapStateToProps, { setLang })(Navbar))
