import React from 'react'

function ConfidentEng() {
	return (
		<>
			<section id="plain-header" className="hero-section">
				<h1 className="page-headline">Privacy Policy</h1>
			</section>
			<section id="plain-body">
				<div className="container">
					<p>
						<em>Last Revision: April 19, 2021</em>
					</p>
					<p>iModo commits to strong, secure and transparent privacy practices.</p>
					<p>
						<strong>We do our very best to protect your privacy and avoid any misuse or abuse of your personal data. And because we value your privacy and strive for our services to be safe and enjoyable for everyone:</strong>
					</p>
					<ul>
						<li>
							<strong>We never sell personal data to anyone</strong>
						</li>
						<li>
							<strong>We carry out all processing operations in strict compliance with privacy laws.</strong>
						</li>
						<li>
							<strong>You are in control of your personal information at all times.</strong>
						</li>
					</ul>
					<p>
						<strong>Our Privacy Policy explains:</strong>
					</p>
					<ul>
						<li>What information we collect and why we collect it</li>
						<li>How we use this information, share and protect it</li>
					</ul>
					<p>
						<strong>Our Privacy Policy applies to:</strong>
					</p>
					<ul>
						<li>Our Customers ( including its internal users (“Customer user(s)”))</li>
						<li>End-Users (“End-Users”) of our Customers who interact with our Customers on their Social Profiles on Supported Platforms</li>
					</ul>
					<p>
						Our Privacy Policy should always be read in conjunction with the specific privacy policy of our Customers which will contain further details regarding the processing of your Personal Data by the Customer, and the
						privacy policy of the Supported Platform(s).
					</p>
					<p>
						If you have questions or complaints regarding our privacy policy or practices, please contact us at
						<a href="mailto:contact@imodo.ai" target="_blank" rel="noopener">
							contact@imodo.ai
						</a>
						.
					</p>
					<h3 className="section-title-left">1- Who is iModo?</h3>
					<p>
						iModo SAS (“iModo”) is a company in Tunis, Tunisia. It was founded in July of 2013. iModo offers a software application (the “Service”) that helps businesses and agencies (individually and collectively “
						Customer(s)”) manage their Social Profiles.
					</p>
					<p>
						By its nature, the Service enables our Customers to bring together their Social Profiles all in one place. The Service helps our Customers manage incoming messages sent to their Social Profiles, comments posted on
						content published on their Social Profiles, scheduling and publishing content on their Social Profiles and analyze the results. It also helps them monitor conversations happening publicly on Supported Platforms as
						long as they contain certain keywords of their choice.
					</p>
					<h3 className="section-title-left">2- What information do we collect and/or process?</h3>
					<p>We collect and/or process different types of information including but not limited to Personal data (“Customer Data”):</p>
					<ul>
						<li>We collect and process information/data from our Customers and/or Customer-Users (“Customer Personal Data”) for our own purposes, such as to provide and administer the Service.</li>
						<li>
							We also process other data on behalf of our Customers (such as content generated, requested or published via the Supported Platforms by our Customers) in accordance with the instructions our Customers give us
							through the Service (including, for example, the information and End User Messages our Customers monitor or collect from Supported Platforms through the Service) (“Customer Content”). Our Customers control how
							their Customer Content is collected and used by them.
							<br />
							Accordingly, we only use Customer Content to provide the Services to our Customer in accordance with the instructions they give us through the Services.
						</li>
					</ul>
					<p>
						<strong>We never collect/process sensitive data as this is not technically allowed through the use of the Service</strong>
					</p>
					<h4 className="title-left">2.1- Customer Personal data</h4>
					<p>
						<u>
							<em>Type of Data concerned</em>
						</u>
					</p>
					<p>When you first register for an iModo Account, and when you use the Service, we collect some data about you as a Customer / Customer User such as:</p>
					<ul>
						<li>your full name,</li>
						<li>your email address</li>
						<li>your company name</li>
						<li>your company’s address</li>
						<li>your company’s phone number</li>
						<li>Social Profile URL</li>
						<li>The content you’ve published on the Social Profiles you’ve connected to the Service</li>
						<li>information we may receive relating to communications you send us, such as queries or comments concerning our Service</li>
					</ul>
					<p>
						iModo also automatically collects and receives certain data from your computer or mobile device, including the activities you perform on the Site, the Service, the Supported Platforms, the type of hardware and
						software you are using (for example, your operating system or browser), and information obtained from cookies (see “Cookies and Related Technologies” below).
					</p>
					<p>
						For example, each time you visit the Site or otherwise use the Service, we automatically collect your IP address, browser and device type, access times, the web page from which you came, the regions from which you
						navigate the web page, and the web page(s) you access (as applicable).
					</p>
					<p>
						<u>
							<em>How we use it</em>
						</u>
					</p>
					<p>iModo uses Customer Personal Data for the following general purposes:</p>
					<ul>
						<li>to identify you when you login to your account on the Service</li>
						<li>to enable us to operate the Service and provide it to you</li>
						<li>to verify your transactions and for purchase confirmation, billing, security, and authentication (including security tokens for communication with Supported Platforms)</li>
						<li>to analyze the Site or the Service and information about our visitors and users, including research into our user demographics and user behaviour in order to improve our content and the Service</li>
						<li>to contact you about your account and provide customer support, including responding to your comments and questions</li>
						<li>
							to keep you informed about the Service, features, surveys, newsletters, offers, contests and events we think you may find useful or which you have requested from us
							<br />
							to sell or market the Service
						</li>
						<li>to better understand your needs, diagnose problems, analyze trends, improve the features and usability of the Services, and better understand and market to our customers and users</li>
						<li>to keep the Service safe and secure</li>
					</ul>
					<h4 className="title-left">2.2- Customer Content</h4>
					<p>
						When our Customers connect a Social Profile to the Service they can choose to instantly collect, process, share and access such Social Profile via the Service (subject to the terms of service and privacy policies
						with the Supported Platforms).
					</p>
					<p>In this way, our Customers Users can obtain, use and analyze data from Supported Platforms of their choosing, and also create, post, transmit, view, display or share data or information through the Service.</p>
					<p>
						<u>
							<em>Type of data concerned:</em>
						</u>
					</p>
					<ul>
						<li>Customer generated content (such as messages (including direct or private messages), posts, comments, profiles picture, images, feeds or communications exchanged on the Supported Platforms)</li>
						<li>End Users Messages and/or name</li>
						<li>End Users Social Profile URLs of the on Supported Platforms</li>
						<li>Supported Platforms user IDs</li>
					</ul>
					<p>
						<u>
							<em>How we use it</em>
						</u>
					</p>
					<p>iModo uses Customer Content for the following purposes:</p>
					<ul>
						<li>To identify the author of an End User Message</li>
						<li>To aggregate all End User Messages from the same person and provide an history of all past conversation between and End User and a Customer</li>
					</ul>
					<p>
						<em>Third-Party Apps and Social Networks</em>
					</p>
					<p>
						The Service enables an ecosystem where you can choose to connect with Supported Platforms. If you choose to send your content to a Supported Platform, iModo is no longer responsible for that content from the point in
						time that it leaves the Service. Instead, the content will be in the control of the relevant Supported Platforms’ terms and privacy policies. For example, if you use the Service to post a message on Facebook,
						Facebook’s privacy policy and terms of service will apply to that message on Facebook’s server.
					</p>
					<p>
						While we attempt to facilitate access only to those Supported Platforms that share our respect for your privacy, we cannot take responsibility for the content or privacy policies of any Supported Platform. We
						encourage you to carefully review the privacy policies of any Supported Platform you access via the Services. For reference, the privacy policies of our current supported platforms are as follows:
					</p>
					<ul>
						<li>
							<a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener">
								https://www.facebook.com/policy.php
							</a>
						</li>
						<li>
							<a href="https://help.instagram.com/402411646841720" target="_blank" rel="noopener">
								https://help.instagram.com/402411646841720
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener">
								https://www.linkedin.com/legal/privacy-policy
							</a>
						</li>
						<li>
							<a href="http://www.google.com/policies/privacy" target="_blank" rel="noopener">
								http://www.google.com/policies/privacy
							</a>
						</li>
					</ul>
					<h4 className="title-left">2.3- Revoking access to the Supported Platforms</h4>
					<p>
						As the Service relies entirely on its connections to Supported Platforms and their APIs (such as the Facebook API, the Instagram API, the Twitter API, the LinkedIn API and the YouTube API services), if you wish to
						ensure that the Service doesn’t access data from the Social Profiles you have previously connected to the Service, this is how you should proceed
					</p>
					<ul>
						<li>
							For YouTube:&nbsp;
							<a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noopener">
								https://security.google.com/settings/security/permissions
							</a>
						</li>
						<li>
							For Facebook:&nbsp;
							<a href="https://www.facebook.com/help/204306713029340" target="_blank" rel="noopener">
								https://www.facebook.com/help/204306713029340
							</a>
						</li>
						<li>
							For Instagram:&nbsp;
							<a href="https://www.facebook.com/help/204306713029340" target="_blank" rel="noopener">
								https://www.facebook.com/help/204306713029340
							</a>
						</li>
						<li>
							For LinkedIn:&nbsp;
							<a href="https://www.linkedin.com/pulse/remove-third-party-apps-connected-your-linkedin-hector-rodriguez/" target="_blank" rel="noopener">
								https://www.linkedin.com/pulse/remove-third-party-apps-connected-your-linkedin-hector-rodriguez/
							</a>
						</li>
					</ul>
					<h3 className="section-title-left">3- Cookies and related technologies</h3>
					<p>
						Like many websites, we collect certain information through the use of “cookies,” which are small text files that are saved by your browser when you access our Site. We may use both session cookies and persistent
						cookies to identify that you’ve logged in to the Site and to tell us how and when you interact with our Site.
					</p>
					<p>We may also use cookies to monitor aggregate usage and web traffic routing on our Site and to customize and improve our Service.</p>
					<p>By visiting the Site or using the Service you agree to the use of cookies in your browser and HTML based emails.</p>
					<h3 className="section-title-left">4- How is your information secured?</h3>
					<p>
						<strong>The security of your personal information is important to us.</strong>
					</p>
					<p>
						iModo has implemented various measures to ensure that the information is adequately protected against unauthorized access, use, disclosure and destruction. Please keep in mind that risk can never be eliminated but
						can be significantly mitigated and reduced. You should bear in mind that submission of information over the internet is never entirely secure.
					</p>
					<p>
						All measures which iModo has taken significantly reduce the risk. iModo shall not be held liable by any third party, including you, in any event of unauthorized access, use and/or disclosure of information provided
						that such is not due to Gross Negligence, willful misconduct, fraud or bad faith by iModo.
					</p>
					<p>Security measures adopted by iModo include:</p>
					<ul>
						<li>Access to the information stored within iModo’s servers is restricted to a limited number of iModo employees;</li>
						<li>
							We use industry best practices to keep any information collected and/or transmitted to the Supported Platforms secure. The Site and the Service are all secured by SSL protocol, which provides encryption to all
							data transmitted;
						</li>
						<li>iModo’s servers are protected by a) firewalls establishing a barrier between our trusted, secure internal network and the Internet and b) IP restrictions, limiting access to whitelisted IPs;</li>
						<li>
							Each Customer may only access information pertaining to its Social Profile that it is tracking and to the specific End Users interacting with such Social Profile. For Twitter and Instagram, each Customer may also
							access public content posted by End Users and containing specific keywords;
						</li>
						<li>
							iModo uses third-party vendors and hosting partners (such as Amazon AWS) to provide the necessary hardware, software, networking, storage, and related technology to run the Services. These vendors all offer
							best-in-class security, you can see Amazon’s security features here: aws.amazon.com/security;
						</li>
						<li>iModo reviews its information collection and processing practices periodically and will review and amend this Privacy Policy accordingly.</li>
						<li>
							iModo uses third party vendors to prevent attacks on the Site or the Service and destruction or theft of its data. Such vendors also monitor potential attacks to allow iModo to inform its Customers in the event
							their Customer Content and/or iModo Data has been compromised;
						</li>
					</ul>
					<p>
						iModo takes reasonable security measures to protect your personal information to prevent loss, misuse, unauthorized access, disclosure, alteration, and destruction. Please be aware, however, that despite our efforts,
						no security measures are 100% bulletproof.
					</p>
					<h3 className="section-title-left">5- Your rights: Access, change, deletion, disclosure</h3>
					<p>You have the right to object to the processing and collection of your personal data, and to withdraw your consent at any time, except where otherwise determined by applicable law.</p>
					<p>Whenever you withdraw consent, you acknowledge and accept that this will immediately terminate your use of the Service.</p>
					<p>
						Accordingly, you can request modification or deletion of your information at any time by sending an email to
						<a href="mailto:contact@imodo.ai" target="_blank" rel="noopener">
							contact@imodo.ai
						</a>
						, with your first and last name and the URL of all the Social Profiles you have connected to the Service. Such a request will have to be sent from the email address used to create your account on the Service.
					</p>
					<p>
						Please note that certain personal information may need to be retained by iModo for a period of time following cancellation of your account where this is necessary for our legitimate business purposes or required or
						authorized by applicable law.
					</p>
					<p>
						You should be aware that iModo acts as a conduit between our Customers and the various Supported Platforms we support. In several instances, the content published via iModo will not be in iModo’s custody or control,
						and any content that has been shared by you through any Supported Platform via the Services may continue to be available to third parties and the public at large, as this content is now under the control of the
						operators of the Supported Platforms.
					</p>
					<p>
						iModo does not rent or sell your Customer Personal Data or Customer Content, but we may disclose such data to a limited set of trusted third parties in the situations explained below, for which you, by using our
						Service, hereby explicitly consent. We will disclose your personal information where we are bound to do so, at law or via a court order as well as to meet any legal or regulatory requirement or obligations. We will
						use all reasonable efforts to ensure that those requirements or obligations are in accordance with applicable law;
					</p>
					<p>
						We reserve the right to disclose your information to any third party if we have reasonable information to believe that the disclosure is necessary for the purpose of an investigation and/or for the enforcement of any
						breaches of the Terms of Service (if applicable), to detect, prevent or otherwise address fraud, security, technical issues or other irregularities or illegalities, protect the rights and interests as well as the
						property of iModo;
					</p>
					<p>We reserve the right to disclose your information to parent companies, subsidiaries, joint ventures, or other companies under common control with iModo;</p>
					<p>
						We reserve the right to disclose your information if we are acquired by or merged with another entity (in which case we will require such entity to assume our obligations under this Privacy Policy or inform you that
						you are covered by a new privacy policy);
					</p>
					<h3 className="section-title-left">6- Notices and revision to the Privacy Policy</h3>
					<p>
						If You have any concern about your privacy, you are kindly requested to forward an email to iModo at
						<a href="mailto:contact@imodo.ai" target="_blank" rel="noopener">
							contact@imodo.ai
						</a>
						containing a detailed description of your concerns. iModo will do its best to resolve such issues within a reasonable time.
						<br />
						We may revise this Privacy Policy from time to time.
						<br />
						The most current version of the policy, found at https://imodo.ai/privacy-policy will govern our use of your information collected and processed from the Service.
					</p>
					<h4 className="title-left">6.1- Consent</h4>
					<p>
						By using the Service, or submitting personal information to the Service, or collecting personal information from Supported Platforms via the Service, you consent to the collection, transfer, storage disclosure, and
						use of your personal information in the manner set out in this Privacy Policy. If you do not consent to the use of your personal information in these ways, you shall refrain from using the Service.
					</p>
					<h4 className="title-left">6.2- Governing law and dispute resolution</h4>
					<p>
						This Privacy Policy forms an integral part of our Terms of Services.
						<br />
						The governing law and dispute resolution mechanism found in Our Terms of Service shall also apply to Our Privacy Policy
					</p>
					{/* <h4 className="title-left">7.3- Definitions</h4>
					<p>
						<strong>“EU Data Protection Law”</strong> means (i) prior to 25th May 2018, European Union Directive 95/46/EC; and (ii) on and after 25th May 2018, European Union Regulation 2016/679 (“<strong>GDPR</strong>”).
					</p>
					<p>
						<strong>“End-User(s)”</strong> means a user of a “Supported Platform” who interacts with a Customer through a Social Profile
					</p>
					<p>
						<strong>“End-User(s) Message”</strong> means a private or public message sent by an End User to any of our Customers via a Social Profile. It also means any publicly available content posted by an End User on a
						Supported Platform that can be searched and retrieved by a Customer via a search on the concerned Supported Platform or using the Service through available Supported Platforms’ APIs.
					</p>
					<p>
						<strong>“Personal Data”</strong> has the meaning given to them in the GDPR
					</p>
					<p>
						<strong>“Service”</strong> means services provided to you by iModo based on the plan you have purchased. It includes all the free tools that can be found at
						<a href="https://imodo.ai/free-social-media-marketing-tools">https://imodo.ai/free-social-media-marketing-tools</a>.
					</p>
					<p>
						<strong>“Site”</strong> shall mean the website www.iModo.com, in addition to any sub-pages that are integrated within this website;
					</p>
					<p>
						<strong>“Social Profile”</strong> means a personal or business profile created by a Customer on a Supported Platform. That includes, but not limited to: Facebook pages, Facebook groups, Facebook personal profiles,
						Twitter profiles, Instagram personal or business profiles, YouTube channels, LinkedIn personal profiles and company pages.
					</p>
					<p>
						<strong>“Supported Platform(s)”</strong> means the social networking site(s) currently supported by the Service, including Twitter, Facebook, LinkedIn, Instagram, YouTube and other social networks as described on the
						Site;
					</p>
					<p>
						All capitalized terms that are not otherwise defined herein shall have the meaning given to them in our Terms of Service which can be found at
						<a href="https://imodo.ai/terms-of-service">https://imodo.ai/terms-of-service</a>.
					</p> */}
					<p>iModo’s Privacy Policy forms part of its wider Terms of Service. Both the Terms of Service and the Privacy Policy apply to your use of the Service.</p>
				</div>
			</section>
		</>
	)
}

export default ConfidentEng
