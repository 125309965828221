import '../Home.css'

import { Col, Image, Modal, Row, Table } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { detectCurrency, getBillingPlan, getPaymentHistory, getPricingInfos, payPlan } from '../../../redux/actions/billingActions'

import { FaInfoCircle } from 'react-icons/fa'
import Invoice from './Invoice'
import { IoIosClose } from 'react-icons/io'
import OwnerCheckbox from '../Pages/OwnerCheckbox'
import axios from 'axios'
import moment from 'moment'
import { saveInfos } from '../../../redux/actions/billingActions'
// Translations
import { trans } from '../../../Translations'
import { useHistory } from 'react-router-dom'
import numbro from 'numbro'

var sha1 = require('sha1')

const INSTA_ICON = require('../../../assets/images/home/slider-instagram.svg')
const FB_ICON = require('../../../assets/images/home/slider-facebook.svg')
const USER_ICON = require('../../../assets/images/login/price-user.svg')
const CHECKBOX_ICON = require('../../../assets/images/login/price-checkbox.svg')

const guid = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}
	let id = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4()
	console.log('orderID generated ...', id)
	// setSignatureId(id);
	return id
}
let orderID = guid()

function Billing(props) {
	let {
		lang,
		pricingInfos,
		paymentHistory,
		billingPlan,
		currency,
		socialMediaPageSelected,

		// Functions:
		detectCurrency,
		getPricingInfos,
		getPaymentHistory,
		getBillingPlan,
		payPlan,
	} = props

	// Change Prod :
	const paramGpg = {
		prod: { numSite: 'MAR826', passSig: 'el@miR06', password: '7d3c7c976120bf6ab84a31e39308df37', vad: '657700003', url: 'https://www.gpgcheckout.com/Paiement/Validation_paiement.php' },
		preprod: { numSite: 'MAR868', passSig: 'bd$inC96', password: '6677f06ff9439a26d5dd81d49931662f', vad: '258500003', url: 'https://preprod.gpgcheckout.com/Paiement_test/Validation_paiement.php' },
	}
	const [curProd, setcurProd] = useState('prod')
	const userData = JSON.parse(localStorage.getItem('userData'))
	const [showPopover, setShowPopover] = useState(false)
	const [choice, setChoice] = useState({
		id: 'plan',
		value: trans[lang].billing.billingPlan,
	})
	const [invoice, setInvoice] = useState(false)
	const [billingModal, setBillingModal] = useState(false)
	const [choosenOffer, setChoosenOffer] = useState([])
	const [discount, setDiscount] = useState({})
	let [checkboxPeriod, setCheckboxPeriod] = useState('')
	const [checkboxPlan, setCheckboxPlan] = useState('')

	// Payments Details:
	let [firstName, setFirstName] = useState('')
	let [lastName, setLastName] = useState('')
	let [email, setEmail] = useState('')
	let [adress, setAdress] = useState('')
	const [confirm, setConfirm] = useState(true)
	const [tauxConversion, setTauxConversion] = useState()
	const statusColor = { 
		paid: '#139216', 
		unpaid: '#E60A17', 
		planified: '#4B4F56', 
		expired: '#dc3545',
		reimbursed:'#4080ff',
		canceled:'#E60A17',
		refused:'#E60A17',
		chargeback:'#139216',
		pending: '#4080ff', 
	}
	const [phone, setPhone] = useState('')
	const [signatureId, setSignatureId] = useState('')

	let test = axios.get('https://imodo.ai/payment-success').then((res) => {
		console.log('send transaction =>', res.data)
	})
	console.log(test)

	const checkPriceColumn = (idCur, euro, usd, tnd) => {
		if (idCur === 'EUR') {
			let currStringLength = euro.toString().length
			return currStringLength < 3 ? 2 : currStringLength < 4 ? 5 : 6
		} else if (idCur === 'USD') {
			let currStringLength = usd.toString().length
			return currStringLength < 3 ? 2 : currStringLength < 4 ? 5 : 6
		} else {
			let currStringLength = tnd.toString().length
			return currStringLength < 3 ? 2 : currStringLength < 4 ? 5 : 6
		}
	}

	const checkUserOffer = (packName, type) => {
		if (type === 'col') {
			// Column
			if (packName === 'Free Trial') {
				return 'price-colone-grise'
			}
			if (billingPlan.name === packName) {
				return 'price-colone-grise'
			} else {
				return 'price-colone'
			}
		} else {
			// Button
			if (packName === 'Free Trial') {
				return 'price-button-grise m-auto'
			}
			if (billingPlan.name === packName) {
				return 'price-button-grise m-auto'
			} else {
				return 'price-button m-auto'
			}
		}
	}

	const fireModal = (pricing) => {
		setChoosenOffer([pricing])
		setCheckboxPlan(pricing.name)
		setBillingModal(true)
	}

	const handleDiscount = (offer, period) => {
		let discounts = {
			/* payement_fr: 0, payment_do: 0, payement: 0 */
		}

		// console.log("Discount this =>", offer, " On This ", discounts);

		if (period === 1) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			setDiscount({})
		} else if (period === 6) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			discounts.payement_fr = offer.payement_fr * 6 - offer.payement_fr * 6 * 0.05
			discounts.payment_do = offer.payment_do * 6 - offer.payment_do * 6 * 0.05
			discounts.payement = offer.payement * 6 - offer.payement * 6 * 0.05
			setDiscount(discounts)
		} else if (period === 3) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			discounts.payement_fr = offer.payement_fr * 6 - offer.payement_fr * 3 * 0.05
			discounts.payment_do = offer.payment_do * 3 - offer.payment_do * 3 * 0.05
			discounts.payement = offer.payement * 3 - offer.payement * 3 * 0.05
			setDiscount(discounts)
		} else if (period === 12) {
			checkboxPeriod = period
			setCheckboxPeriod(period)
			discounts.payement_fr = offer.payement_fr * 12 - offer.payement_fr * 12 * 0.1
			discounts.payment_do = offer.payment_do * 12 - offer.payment_do * 12 * 0.1
			discounts.payement = offer.payement * 12 - offer.payement * 12 * 0.1
			setDiscount(discounts)
		}
	}

	// const guid = () => {
	//     function s4() {
	//       return Math.floor((1 + Math.random()) * 0x10000)
	//                  .toString(16)
	//                  .substring(1);
	//     }
	//     let id = s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4();
	//     console.log("orderID generated ...", id);
	//     // setSignatureId(id);
	//     return id;
	// };

	const renderPopover = () => (
		<div className="d-flex manage-products-popover" onMouseEnter={() => setShowPopover(true)} onMouseLeave={() => setShowPopover(false)}>
			<p className="m-auto">{trans[lang].billing.infoPopover}</p>
		</div>
	)

	const handlePopover = (delay) => {
		setTimeout(() => {
			setShowPopover(!showPopover)
		}, delay)
	}
	const conversionCurrency = async ({ from, to }) => {
		const conv = await axios(`https://free.currconv.com/api/v7/convert?q=${from}_${to}&compact=ultra&apiKey=678de9206f8e50ff6dfb`)
		//console.log(conv)
		setTauxConversion(conv?.data[`${from}_${to}`])
	}
	const calculateAmount = (offer, discount, period) => {
		let price = { priceTnd: '', priceSecond: '' }
		console.log('offer', offer)
		if (period === '' || period === 1 || period === undefined) {
			console.log('Returning Amount', parseFloat(offer[0]?.payement * 1000))
			price.priceTnd = parseFloat(offer[0]?.payement * 1000)
			price.priceSecondEur = parseFloat(offer[0]?.payement_fr * 100)
			price.priceSecondUsd = parseFloat(offer[0]?.payment_do * 100)
		} else if (period !== 1 && period !== undefined && discount !== {} && discount.payement !== undefined) {
			console.log('Returning Amount', parseFloat(discount.payement * 1000))
			console.log('discount', discount)
			price.priceTnd = parseFloat(discount.payement * 1000)
			price.priceSecondEur = parseFloat(discount?.payement_fr * 100)
			price.priceSecondUsd = parseFloat(discount?.payment_do * 100)
		}
		return price
	}
	const getAmount = (offer, discount, period) => {
		console.log('Discount is', discount)
		console.log('Offer payement is', offer[0]?.payement)
		console.log('Period', period)
		console.log(calculateAmount(offer, discount, period))
		let amount = { amountTnd: '', amountSecond: '' }
		switch (currency?.id) {
			case 'TND':
				amount.amountTnd = calculateAmount(offer, discount, period)?.priceTnd
				amount.amountSecond = ''
				break
			case 'EUR':
				amount.amountTnd = calculateAmount(offer, discount, period)?.priceSecondEur * tauxConversion * 10
				amount.amountSecond = calculateAmount(offer, discount, period)?.priceSecondEur
				break
			case 'USD':
				amount.amountTnd = calculateAmount(offer, discount, period)?.priceSecondUsd * tauxConversion * 10
				amount.amountSecond = calculateAmount(offer, discount, period)?.priceSecondUsd
				break
			default:
				break
		}
		console.log('amount', amount)

		return amount
	}

	/* const getAmount = (offer, discount, period) => {
		console.log('Discount is', discount)
		console.log('Offer payement is', offer[0].payement)
		console.log('Period', period)

		if (period === '' || period === 1 || period === undefined) {
			console.log('Returning Amount', parseFloat(offer[0].payement * 1000))
			return parseFloat(offer[0].payement * 1000)
		} else if (period !== 1 && period !== undefined && discount !== {} && discount.payement !== undefined) {
			console.log('Returning Amount', parseFloat(discount.payement * 1000))
			return parseFloat(discount.payement * 1000)
		}
	} */

	const dispatch = useDispatch()
	const handleChange = (key) => (e) => {
		switch (key) {
			case 'lastName':
				lastName = e.target.value
				setLastName(e.target.value)
				break
			case 'firstName':
				firstName = e.target.value
				setFirstName(e.target.value)
				break
			case 'email':
				email = e.target.value
				setEmail(e.target.value)
				break
			case 'adress':
				adress = e.target.value
				setAdress(e.target.value)
				break

			default:
				break
		}
		if (firstName != '' && lastName != '' && email != '' && adress != '' && checkboxPeriod != '') setConfirm(false)
		else setConfirm(true)
		console.log({ key, val: e.target.value })
	}

	const handleSubmit = async () => {
		//  let orderID = guid();

		const Agents = checkboxPlan === 'Free Trial' ? pricingInfos[0].number_agent : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].number_agent : checkboxPlan === 'Agencies' ? pricingInfos[2].number_agent : pricingInfos[3].number_agent
		const Pages = checkboxPlan === 'Free Trial' ? pricingInfos[0].number_pages : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].number_pages : checkboxPlan === 'Agencies' ? pricingInfos[2].number_pages : pricingInfos[3].number_pages
		const autoReply =
			checkboxPlan === 'Free Trial'
				? pricingInfos[0].number_autoReply
				: checkboxPlan === 'SME/Artisans'
				? pricingInfos[1].number_autoReply
				: checkboxPlan === 'Agencies'
				? pricingInfos[2].number_autoReply
				: pricingInfos[3].number_autoReply
		const numberUser = checkboxPlan === 'Free Trial' ? pricingInfos[0].number_user : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].number_user : checkboxPlan === 'Agencies' ? pricingInfos[2].number_user : pricingInfos[3].number_user
		const Payment = checkboxPlan === 'Free Trial' ? pricingInfos[0].payement : checkboxPlan === 'SME/Artisans' ? pricingInfos[1].payement : checkboxPlan === 'Agencies' ? pricingInfos[2].payement : pricingInfos[3].payement

		let totalPrice
		switch (currency.id) {
			case 'EUR':
				totalPrice = Object.keys(discount).length ? discount.payement_fr : choosenOffer[0]?.payement_fr

				break
			case 'USD':
				totalPrice = Object.keys(discount).length ? discount.payment_do : choosenOffer[0]?.payment_do
				break

			default:
				totalPrice = Object.keys(discount).length ? discount.payement : choosenOffer[0]?.payement
				break
		}
		totalPrice = numbro(parseFloat(totalPrice)).format({
			mantissa: 2,
		})
		const data = {
			last_name: lastName,
			user_name: firstName,
			email: email,
			adresse: adress,
			payedBy: `${firstName} ${lastName}`,
			idPack: orderID,
			name: checkboxPlan,
			delai: checkboxPeriod,
			number_agent: Agents,
			number_pages: Pages,
			number_autoReply: autoReply,
			number_user: numberUser,
			payment: totalPrice,
		}
		console.log('my data is: ', JSON.stringify(data))
		const idFacebook = localStorage.getItem('authtoken')
		await dispatch(saveInfos(data, idFacebook))
	}

	const renderBillingModal = () => {
		// let orderID = guid();
		console.log(paramGpg[curProd]?.numSite + paramGpg[curProd]?.passSig + orderID + parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountTnd) + currency?.id)
		return (
			<Modal show={billingModal} size={'lg'} centered>
				<Modal.Body className="p-0">
					<form onSubmit={handleSubmit} action={paramGpg[curProd]?.url} method="post">
						{/* <div> */}
						<input name="NumSite" id="NumSite" value={paramGpg[curProd]?.numSite} hidden />
						<input name="Password" id="Password" value={paramGpg[curProd]?.password} hidden />
						<input name="orderID" id="orderID" value={orderID} hidden />
						<input name="Langue" id="Langue" value={lang} hidden />
						<input name="CustTel" id="CustTel" className="formulaire-pricing-input" hidden value={'55111222'} /*onChange={(e) => setPhone(e.target.value)}*/ />

						<input name="Currency" id="Currency" value={currency.id} hidden />

						<input name="Amount" id="Amount" value={parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountTnd)} hidden />
						<input
							name="AmountSecond"
							id="AmountSecond"
							value={parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountSecond) ? parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountSecond) : ''}
							hidden
						/>
						<input name="TauxConversion" id="TauxConversion" value={tauxConversion} hidden />

						{/* {currency.id === "EUR" 
                        ? 
                        pricing.payement_fr 
                        : 
                        currency.id === "USD" 
                        ? 
                        pricing.payment_do 
                        : 
                        pricing.payement}    
                        }
                        <input name="AmountSecond" id="AmountSecond" value="40000" hidden /> */}

						<input name="PayementType" id="PayementType" value="1" hidden />
						<input name="orderProducts" id="orderProducts" value={choosenOffer[0].name} hidden />
						<input name="signature" id="signature" value={sha1(paramGpg[curProd]?.numSite + paramGpg[curProd]?.passSig + orderID + parseInt(getAmount(choosenOffer, discount, checkboxPeriod)?.amountTnd) + currency?.id)} hidden />
						<input name="vad" id="vad" value={paramGpg[curProd]?.vad} hidden />
						<input name="Terminal" id="Terminal" value={currency?.terminal} hidden />
						{/* </div> */}
						<Row className="formulaire-pricing justify-content-center d-flex w-100 h-100">
							<Col lg={12} className="d-flex mb-1">
								<IoIosClose
									color={'#B4B4B4'}
									size={30}
									className="ml-auto"
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setBillingModal(false)
										checkboxPeriod = ''
										setCheckboxPeriod('')
										setChoosenOffer([])
										setDiscount({})
									}}
								/>
							</Col>

							<Col lg={12} className="d-flex">
								<h1 className="mx-auto mb-5">{trans[lang].formPayment.purchaseOrder}</h1>
							</Col>
							{console.log('confirmmmmm', trans[lang].formPayment.confirm)}

							<Col lg={5} className="d-flex flex-column mb-2">
								<label className="">{trans[lang].formPayment.firstName}*</label>
								<input autoComplete="off" name="CustLastName" id="CustLastName" className="formulaire-pricing-input" value={lastName} onChange={handleChange('lastName')} />
							</Col>

							<Col lg={5} className="d-flex flex-column mb-2">
								<label className="">{trans[lang].formPayment.lastName}*</label>
								<input autoComplete="off" name="CustFirstName" id="CustFirstName" className="formulaire-pricing-input" value={firstName} onChange={handleChange('firstName')} />
							</Col>

							<Col lg={5} className="d-flex flex-column">
								<label className="">{trans[lang].formPayment.email}*</label>
								<input autoComplete="off" name="EMAIL" id="EMAIL" className="formulaire-pricing-input" value={email} onChange={handleChange('email')} />
							</Col>

							<Col lg={5} className="d-flex flex-column">
								<label className="">{trans[lang].formPayment.address}*</label>
								<input autoComplete="off" name="CustAddress" id="CustAddress" className="formulaire-pricing-input" value={adress} onChange={handleChange('adress')} />
							</Col>

							{/* <Col lg={5} className="d-flex flex-column" >
                        <label className="">Phone number*</label>
                        <input autoComplete="off" name="CustTel" id="CustTel" className="formulaire-pricing-input" hidden value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </Col>                                                           
                 */}
							<Col lg={10} className="d-flex flex-column">
								<p>*{trans[lang].formPayment.privideInform}</p>
								<hr />
							</Col>
							<Col lg={10} className="d-flex flex-column">
								<h2>{trans[lang].formPayment.selectPlan}</h2>
							</Col>

							{pricingInfos.map(
								(pricing, index) =>
									pricing.name !== 'Free Trial' && (
										<Col lg={5} key={index * Math.random() * 2}>
											<div
												className={checkboxPlan === pricing.name ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
												onClick={() => {
													setChoosenOffer([pricing])
													setCheckboxPlan(pricing.name)
													checkboxPeriod = ''
													setCheckboxPeriod('')
													setDiscount({})
												}}
											>
												<OwnerCheckbox checked={checkboxPlan === pricing.name ? true : false} color={true} />
												<p className="my-auto ml-3">{trans[lang].billingPlan[pricing.name]}</p>
											</div>
										</Col>
									)
							)}

							<Col lg={5}></Col>

							<Col lg={10} className="d-flex flex-column">
								<h2 className="mt-4">{trans[lang].formPayment.selectPeriod}</h2>
							</Col>

							<Col lg={5} className="">
								<div
									className={checkboxPeriod === 1 ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
									onClick={() => {
										handleDiscount(choosenOffer[0], 1)
									}}
								>
									<OwnerCheckbox checked={checkboxPeriod === 1 ? true : false} color={true} />
									<p className="my-auto ml-3">{trans[lang].formPayment.oneMonth}</p>
								</div>
							</Col>
							<Col lg={5} className="">
								<div
									className={checkboxPeriod === 6 ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
									onClick={() => {
										handleDiscount(choosenOffer[0], 6)
									}}
								>
									<OwnerCheckbox checked={checkboxPeriod === 6 ? true : false} color={true} />
									<p className="my-auto ml-3">6 {trans[lang].formPayment.months}</p>{' '}
									<p className="my-auto ml-1" style={{ fontSize: '10px' }}>
										({trans[lang].formPayment.get5})
									</p>
								</div>
							</Col>

							<Col lg={5} className="">
								<div
									className={checkboxPeriod === 12 ? 'formulaire-pricing-checkbox-box-rose mt-3 d-flex' : 'formulaire-pricing-checkbox-box mt-3 d-flex'}
									onClick={() => {
										handleDiscount(choosenOffer[0], 12)
									}}
								>
									<OwnerCheckbox checked={checkboxPeriod === 12 ? true : false} color={true} />
									<p className="my-auto ml-3">12 {trans[lang].formPayment.months}</p>
									<p className="my-auto ml-1" style={{ fontSize: '10px' }}>
										({trans[lang].formPayment.get10})
									</p>
								</div>
							</Col>

							<Col lg={10} className="d-flex mt-4 justify-content-center">
								{Object.keys(discount).length ? (
									<>
										<p id="total-amount">{trans[lang].formPayment.totalAmount} :</p>
										<p id="price">
											{currency.id === 'EUR'
												? numbro(parseFloat(discount.payement_fr)).format({
														mantissa: 2,
												  })
												: currency.id === 'USD'
												? numbro(parseFloat(discount.payment_do)).format({
														mantissa: 2,
												  })
												: numbro(parseFloat(discount.payement)).format({
														mantissa: 2,
												  })}{' '}
											{currency.value}
										</p>
										<p id="tax">{trans[lang].formPayment.taxIncluded}</p>
									</>
								) : (
									<>
										<p id="total-amount">{trans[lang].formPayment.totalAmount} :</p>
										<p id="price">
											{currency.id === 'EUR'
												? numbro(parseFloat(choosenOffer[0]?.payement_fr)).format({
														mantissa: 2,
												  })
												: currency.id === 'USD'
												? numbro(parseFloat(choosenOffer[0]?.payment_do)).format({
														mantissa: 2,
												  })
												: numbro(parseFloat(choosenOffer[0]?.payement)).format({
														mantissa: 2,
												  })}{' '}
											{currency.value}
										</p>
										<p id="tax">{trans[lang].formPayment.taxIncluded}</p>
									</>
								)}
							</Col>

							<Col lg={3} className="d-flex mt-4">
								<button className="price-button" type="submit" disabled={confirm}>
								{ trans[lang].formPayment.confirm}
								</button>
							</Col>
						</Row>
					</form>
				</Modal.Body>
			</Modal>
		)
	}

	useEffect(() => {
		detectCurrency().then(() => {
			getBillingPlan().then(() => {
				getPricingInfos().then(() => {
					getPaymentHistory()
				})
			})
		})
	}, [])
	useEffect(() => {
		console.log('checkboxPeriod', checkboxPeriod)
		if (firstName != '' && lastName != '' && email != '' && adress != '' && checkboxPeriod != '') setConfirm(false)
		else setConfirm(true)
	}, [checkboxPeriod])
	useEffect(() => {
		conversionCurrency({ from: currency?.id, to: 'TND' })
	}, [currency])
	/* useEffect(() => {
		console.log({ firstName, lastName, email, adress })
		if (firstName != '' && lastName != '' && email != '' && adress != '' && checkboxPeriod != '') setConfirm(false)
		else setConfirm(true)
	}, [firstName, lastName, email, adress]) */

	return (
		<div>
			{billingModal && renderBillingModal()}

			{invoice ? (
				<Invoice setInvoice={setInvoice} data={invoice} />
			) : (
				<>
					<Row className="mt-5">
						<p className="home-big-title">{trans[lang].billing.title}</p>
						<FaInfoCircle style={{ transitionDuration: '300ms' }} color={!showPopover ? '#4080FF' : '#E5007D'} size={'15'} onMouseEnter={() => handlePopover('20')} onMouseLeave={() => handlePopover('700')} />
						{showPopover && renderPopover()}
					</Row>

					<Row>
						{/* Top bar */}
						<Col lg={11} className="d-flex p-0 justify-content-between mt-2">
							<div
								className={choice.id === 'plan' ? 'billing-choice-box-active d-flex mr-auto' : 'billing-choice-box d-flex mr-auto'}
								onClick={() =>
									setChoice({
										id: 'plan',
										value: trans[lang].billing.billingPlan,
									})
								}
							>
								<p className="m-auto">{trans[lang].billing.billingPlan}</p>
							</div>

							<div
								className={choice.id === 'change' ? 'billing-choice-box-active d-flex mx-3' : 'billing-choice-box d-flex mx-3'}
								style={{ width: '420px!important' }}
								onClick={() =>
									setChoice({
										id: 'change',
										value: trans[lang].billing.changePlan,
									})
								}
							>
								<p className="m-auto">{trans[lang].billing.changePlan}</p>
							</div>

							<div
								className={choice.id === 'payments' ? 'billing-choice-box-active d-flex ml-auto' : 'billing-choice-box d-flex ml-auto'}
								onClick={() =>
									setChoice({
										id: 'payments',
										value: trans[lang].billing.payments,
									})
								}
							>
								<p className="m-auto">{trans[lang].billing.payments}</p>
							</div>
						</Col>

						{/* Content */}
						{choice.id === 'plan' ? (
							<Col lg={12} className="d-flex p-0 mt-5">
								{billingPlan && (
									<div className="mr-auto billing-box px-3 py-2">
										<div className="d-flex mb-5">
											<p className="mr-auto my-auto billing-plan-gray-text">
												{trans[lang].billingPlan.subscribedTo} { trans[lang].billingPlan[billingPlan.name]}
											</p>
											<div className="ml-auto my-auto d-flex billing-plan-pink-btn py-2 px-4">
												<p className="m-auto">{trans[lang].billingPlan[billingPlan.name]}</p>
											</div>
										</div>

										<div>
											<Col lg={lang === 'fr' ? 6 : 5} className="p-0">
												<Col lg={12} className="d-flex mb-2 p-0">
													<p className="mr-auto billing-plan-lightgray-text">{trans[lang].billingPlan.socialPages}</p>
													<p
														className="ml-auto billing-plan-lightgray-text"
														style={{
															color: 'black',
														}}
													>
														{billingPlan.socialPages}
													</p>
												</Col>
											</Col>

											<Col lg={lang === 'fr' ? 6 : 5} className="p-0">
												<Col lg={12} className="d-flex mb-2 p-0">
													<p className="mr-auto billing-plan-lightgray-text">{trans[lang].billingPlan.teamMembers}</p>
													<p
														className="ml-auto billing-plan-lightgray-text"
														style={{
															color: 'black',
														}}
													>
														{billingPlan.teamMember}
													</p>
												</Col>
											</Col>

											<Col lg={lang === 'fr' ? 6 : 5} className="p-0">
												<Col lg={12} className="d-flex mb-2 p-0">
													<p className="mr-auto billing-plan-lightgray-text">{trans[lang].billingPlan.activeAgents}</p>
													<p
														className="ml-auto billing-plan-lightgray-text"
														style={{
															color: 'black',
														}}
													>
														{billingPlan.activeAgent}
													</p>
												</Col>
											</Col>

											<Col lg={lang === 'fr' ? 6 : 5} className="p-0">
												<Col lg={12} className="d-flex mb-2 p-0">
													<p className="mr-auto billing-plan-lightgray-text">{trans[lang].billingPlan.monthlyReplies}</p>
													<p
														className="ml-auto billing-plan-lightgray-text"
														style={{
															color: 'black',
														}}
													>
														{billingPlan.monthlyReplies}
													</p>
												</Col>
											</Col>
										</div>

										<div className="d-flex">
											<div className="ml-auto d-flex">
												<p className="billing-plan-lightgray-text my-auto">{trans[lang].billingPlan.planValid} &nbsp;</p>
												<p className="billing-plan-pink-text my-auto">{billingPlan.delai}</p>
											</div>
										</div>

										<hr
											style={{
												width: '100%',
												border: '1px solid #B4B4B4',
											}}
										/>

										<div className="d-flex">
											<div className="ml-auto d-flex">
												<div
													className="billing-plan-green-btn py-2 px-4"
													onClick={() =>
														setChoice({
															id: 'change',
															value: trans[lang].billing.changePlan,
														})
													}
												>
													<p className="m-auto">{trans[lang].billingPlan.upgradePlan}</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</Col>
						) : choice.id === 'change' ? (
							<Col lg={11} className="d-flex p-0 mt-5">
								<Row>
									{pricingInfos.length > 0 &&
										currency.id.length > 0 &&
										pricingInfos.map((pricing, index) => (
											<Col lg={3} key={index + Math.random()} className="d-flex p-3">
												<Row>
													<Col
														lg={12}
														className="mt-2"
														style={{
															height: '60px',
														}}
													>
														<p className="price-titre-item mr-auto my-auto">{trans[lang].billingPlan[pricing.name]}</p>
														{/* <p className="price-titre-item mr-auto my-auto">{lang === 'fr' ? pricing.name_fr : pricing.name}</p> */}
														{pricing.company && <p className="price-sous-titre mr-auto my-auto">{lang === 'fr' ? pricing.company_fr : pricing.company}</p>}
													</Col>

													<div className="py-3 px-4 ">
														<Row className={checkUserOffer(pricing.name, 'col')}>
															<Col lg={12} className="d-flex">
																<Row className="justify-content-center w-100">
																	<Col xs={2} className="d-flex p-0">
																		<p
																			className="price-price mx-auto mb-auto ml-auto"
																			style={{
																				fontSize: '19px',
																				color: '#E5007D',
																			}}
																		>
																			{currency.value}
																		</p>
																	</Col>
																	<Col lg={() => checkPriceColumn(currency.id, pricing.payement_fr, pricing.payment_do, pricing.payement)} className="d-flex p-0">
																		<p
																			className="price-price my-auto ml-auto"
																			style={{
																				color: '#E5007D',
																			}}
																		>
																			{currency.id === 'EUR' ? pricing.payement_fr : currency.id === 'USD' ? pricing.payment_do : pricing.payement}
																		</p>
																	</Col>
																	<Col xs={3} className="d-flex p-0">
																		<p
																			className="price-price my-auto mr-auto"
																			style={{
																				fontSize: '14px',
																			}}
																		>
																			/{trans[lang].pricing.month}
																		</p>
																	</Col>
																</Row>
															</Col>

															<Col lg={12} className="d-flex">
																<p className="price-sous-titre-price mx-auto mt-2">{trans[lang].pricing.supportedNetwork}</p>
															</Col>

															<Col
																lg={12}
																className="d-flex"
																style={{
																	justifyContent: 'center',
																}}
															>
																<Image
																	src={FB_ICON}
																	className=" m-1 "
																	style={{
																		width: '7%',
																	}}
																/>
																<Image
																	src={INSTA_ICON}
																	className=" m-1"
																	style={{
																		width: '7%',
																	}}
																/>
															</Col>

															<Col lg={12} className="d-flex price-sous-titre-price mt-3 mb-4 p-0">
																<Image src={USER_ICON} className="mr-3 price-icon-size" />
																{lang === 'fr' ? pricing.number_pages_fr : pricing.number_pages}, {lang === 'fr' ? pricing.number_user_fr : pricing.number_user}
															</Col>

															<Row
																className="mx-0"
																style={{
																	height: '230px',
																	alignContent: 'start',
																}}
															>
																{pricing.number_agent && (
																	<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																		<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																		{lang === 'fr' ? pricing.number_agent_fr : pricing.number_agent}
																	</Col>
																)}

																{pricing.history && (
																	<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																		<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																		{lang === 'fr' ? pricing.history_fr : pricing.history}
																	</Col>
																)}

																{pricing.performance && (
																	<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																		<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																		{lang === 'fr' ? pricing.performance_fr : pricing.performance}
																	</Col>
																)}

																{pricing.training && (
																	<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																		<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																		{lang === 'fr' ? pricing.training_fr : pricing.training}
																	</Col>
																)}

																{pricing.support && (
																	<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																		<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																		{lang === 'fr' ? pricing.support_fr : pricing.support}
																	</Col>
																)}

																{pricing.number_autoReply && (
																	<Col lg={12} className="d-flex price-checkbox-titre mb-3 p-0">
																		<Image src={CHECKBOX_ICON} className="mr-3 price-icon-size" />
																		{lang === 'fr' ? pricing.number_autoReply_fr : pricing.number_autoReply}
																	</Col>
																)}
															</Row>

															<Col lg={12} className="d-flex">
																<button
																	className={checkUserOffer(pricing.name, 'btn')}
																	onClick={() => {
																		checkUserOffer(pricing.name, 'col') === 'price-colone' && fireModal(pricing)
																	}}
																>
																	{checkUserOffer(pricing.name, 'col') === 'price-colone-grise' ? trans[lang].pricing.actualPlan : trans[lang].pricing.choosePlan}
																</button>
															</Col>
														</Row>
													</div>
												</Row>
											</Col>
										))}
								</Row>
							</Col>
						) : (
							<Col lg={11} className="p-0 mt-5">
								<p className="billing-payments-title mb-3">{trans[lang].billingPayments.paymentsHistory}</p>

								{paymentHistory.length > 0 && (
									<Table striped bordered responsive>
										<thead>
											<tr>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.date}</th>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.payment}</th>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.status}</th>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.paidBy}</th>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.desc}</th>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.period}</th>
												<th className="billing-payments-table-title">{trans[lang].billingPayments.invoice}</th>
											</tr>
										</thead>
										<tbody>
											{paymentHistory.map((history, index) => (
												<tr key={index}>
													<td className="billing-payments-table-medium-text">{history.date_Create}</td>
													<td className="billing-payments-table-medium-text">
														{history.payment}
														{currency.value}
													</td>
													{
														<td
															className="billing-payments-table-semibold-text"
															style={{
																color: statusColor[history.status?.toLowerCase()],
															}}
														>
															{trans[lang].billingPayments[history.status?.toLowerCase()]}
														</td>
														/* history.status === 'Paid' ? (
															<td
																className="billing-payments-table-semibold-text"
																style={{
																	color: '#139216',
																}}
															>
																{trans[lang].billingPayments.paid}
															</td>
														) : history.status === 'Unpaid' ? (
															<td
																className="billing-payments-table-semibold-text"
																style={{
																	color: '#E60A17',
																}}
															>
																{trans[lang].billingPayments.unpaid}
															</td>
														) : (
															<td
																className="billing-payments-table-semibold-text"
																style={{
																	color: '#4B4F56',
																}}
															>
																{trans[lang].billingPayments.expired}
															</td>
														) */
													}
													<td className="billing-payments-table-medium-text">{history.payed_by}</td>
													<td className="billing-payments-table-bold-text">{trans[lang].billingPlan[history.description]}</td>
													<td className="billing-payments-table-medium-text">{history.period}</td>
													<td
														className="billing-payments-table-semibold-text"
														onClick={() => setInvoice(history)}
														style={{
															color: '#4080FF',
															cursor: 'pointer',
														}}
													>
														{trans[lang].billingPayments.view}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								)}
							</Col>
						)}
					</Row>
				</>
			)}
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		lang: state.socialMediaR.lang,
		socialMediaPageSelected: state.socialMediaR.socialMediaPageSelected,
		pricingInfos: state.billingR.pricingInfos,
		paymentHistory: state.billingR.paymentHistory,
		billingPlan: state.billingR.billingPlan,
		currency: state.billingR.currency,
	}
}

export default connect(mapStateToProps, {
	getPricingInfos,
	getBillingPlan,
	detectCurrency,
	payPlan,
	getPaymentHistory,
})(Billing)
