import React from 'react'

function ConfidentFr() {
	return (
		<>
			<section id="plain-header" className="hero-section">
				<h1 className="page-headline">IMODO | Politique de confidentialité</h1>
			</section>
			<section className="body">
				<div className="container">
					<p>
						<em>Dernière révision : le 19 avril 2018</em>
					</p>
					<p>Imodo s’engage à souscrire à des pratiques strictes, sécurisées et transparentes en matière de confidentialité, cette dernière étant au centre de nos préoccupations.</p>
					<p>
						<strong>
							Nous mettons tout en oeuvre pour préserver votre confidentialité et prévenir toute utilisation abusive ou à mauvais escient de vos données à caractère personnel. La confidentialité étant au centre de nos
							préoccupations, et la sécurité et la convivialité de nos services des priorités :
						</strong>
					</p>
					<ul>
						<li>
							<strong>Nous ne céderons jamais les données à caractère personnel à quiconque</strong>
						</li>
						<li>
							<strong>Nous réalisons toutes les opérations de traitement dans le strict respect de la réglementation européenne en matière de confidentialité.</strong>
						</li>
						<li>
							<strong>Vous garderez le contrôle sur vos informations personnelles à tout moment.</strong>
						</li>
					</ul>
					<p>
						<strong>Notre Politique de confidentialité précise :</strong>
					</p>
					<ul>
						<li>Quelles informations nous collectons et pourquoi nous les collectons</li>
						<li>Comment nous utilisons, partageons et protégeons ces informations</li>
					</ul>
					<p>
						<strong>Notre Politique de confidentialité s’applique à :</strong>
					</p>
					<ul>
						<li>nos Clients (y compris leurs utilisateurs internes (le(s) « Utilisateur(s) Client(s)»)</li>
						<li>les Utilisateurs finaux (les « Utilisateurs finaux ») de nos Clients qui interagissent avec nos Clients sur leurs Profils sociaux des Plateformes associées.</li>
					</ul>
					<p>
						Notre Politique de confidentialité sera toujours lue conjointement avec la politique de confidentialité spécifique de nos Clients, qui contiendra des informations supplémentaires concernant le traitement de vos
						Données à caractère personnel par le Client, et avec la politique de confidentialité de ou des Plateforme(s) associées.
					</p>
					<p>
						En cas de questions ou de réclamations concernant notre politique de confidentialité ou nos pratiques, veuillez nous contacter à l’adresse{' '}
						<a href="mailto:contact@Imodo.ai" target="_blank" rel="noopener">
							contact@Imodo.ai
						</a>
						.
					</p>
					<h3 className="section-title-left">1- Qui est Imodo ?</h3>
					<p>
						Imodo SAS (« Imodo ») est une société qui a son siège social à Tunis, Tunisie. Elle a été fondée en juillet 2013. Imodo propose une application logicielle (le « Service ») qui permet aux entreprises et agences
						(individuellement et collectivement les « Client(s) ») de gérer leurs Profils sociaux.
					</p>
					<p>
						De par sa nature, le Service permet à nos Clients de regrouper tous leurs Profils sociaux au même endroit. Le Service permet à nos Clients de gérer les messages entrants envoyés à leurs profils sociaux, les
						commentaires publiés sous les contenus publiés sur leurs Profils sociaux, la planification et la publication de contenus sur leurs Profils sociaux et d’analyser les résultats. Il leur permet également de surveiller
						les conversations publiées sur les Plateformes associées, pour autant qu’elles contiennent des mots clés de leur choix.
					</p>
					<p>Imodo applique la loi tunisienne en matière de protection des données, qui intègre l’ensemble de la réglementation applicable en matière de protection des données.</p>
					<h3 className="section-title-left">2- Quelles sont les informations que nous collectons et/ou traitons ?</h3>
					<p>Nous collectons et / ou traitons différents types d’informations, y compris, sans s’y limiter, des données à caractère personnel (les « Données Client ») :</p>
					<ul>
						<li>
							Nous collectons et traitons les informations / données de nos Clients et / ou Utilisateurs Clients (les « Données à caractère personnel » des Clients ») pour nos propres besoins, telles que la fourniture et
							l’administration du Service.
						</li>
						<li>
							Nous traitons également d’autres données pour le compte de nos Clients (tels que les contenus générés, sollicités ou publiés par nos Clients via les Plateformes associées) conformément aux consignes que nous
							adressent nos Clients par le biais du Service (y compris, par exemple, les informations et les messages de l’Utilisateur final que nos Clients surveillent ou collectent à partir des Plateformes associées via le
							Service) (le « Contenu Client »). Nos Clients contrôlent la manière dont ils collectent et utilisent leur Contenu Client.
							<br />
							En conséquence, nous utilisons le Contenu Client uniquement pour fournir les Services à nos Clients conformément aux consignes qu’ils nous adressent dans le cadre des Services.
						</li>
					</ul>
					<p>
						<strong>Nous ne collectons / ne traitons jamais de données sensibles, étant donné que cela n’est pas techniquement possible dans le cadre du Service.</strong>
					</p>
					<h4 className="title-left">2.1- Les données à caractère personnel du Client</h4>
					<p>
						<u>
							<em>Type de données concernées</em>
						</u>
					</p>
					<p>Lorsque vous créez pour la première fois un compte Imodo et lorsque vous utilisez le Service, nous collectons des données vous concernant en votre qualité de Client / Utilisateur Client, telles que :</p>
					<ul>
						<li>votre nom et votre prénom,</li>
						<li>votre adresse électronique,</li>
						<li>la dénomination de votre entreprise,</li>
						<li>l’adresse de votre entreprise,</li>
						<li>le numéro de téléphone de votre entreprise,</li>
						<li>l’adresse URL de votre Profil social,</li>
						<li>Les contenus que vous avez publiés sur les Profils sociaux que vous avez connectés au Service,</li>
						<li>les informations que nous sommes susceptibles de recevoir concernant les communications que vous nous envoyez, telles que les demandes de renseignement ou les commentaires concernant notre Service.</li>
					</ul>
					<p>
						En outre, Imodo collecte et reçoit automatiquement certaines données depuis votre ordinateur ou votre dispositif mobile, notamment vos activités sur le Site, le Service, les Plateformes associées, le type de matériel
						et de logiciel que vous utilisez (par exemple, votre système d’exploitation ou votre navigateur), ainsi que les informations obtenues à partir des cookies (voir « Cookies et technologies liées » ci-dessous).
					</p>
					<p>
						Par exemple, chaque fois que vous visitez le Site ou utilisez le Service, nous collectons automatiquement votre adresse IP, votre type de navigateur et de dispositif, votre heure d’accès, la page internet à partir de
						laquelle vous avez accédé au site, les régions à partir desquelles vous naviguez sur les pages internet, et la (les) page(s) internet que vous consultez (le cas échéant).
					</p>
					<p>
						<u>
							<em>Comment nous les utilisons</em>
						</u>
					</p>
					<p>Imodo utilise les Données à caractère personnel du Client aux fins générales suivantes :</p>
					<ul>
						<li>afin de vous identifier lorsque vous vous connectez à votre compte sur le Service,</li>
						<li>afin d’assurer le bon fonctionnement du Service et de vous fournir ce dernier,</li>
						<li>
							afin de vérifier vos transactions et confirmer les achats, aux fins de la facturation, la sécurité et l’authentification (y compris les jetons de sécurité dans le cadre de la communication avec les Plateformes
							associées),
						</li>
						<li>
							afin d’analyser le Site ou le Service, ainsi que les informations sur nos visiteurs et utilisateurs, y compris dans le cadre de recherches concernant les paramètres démographiques de nos utilisateurs et le
							comportement des utilisateurs, afin d’optimiser nos contenus et le Service,
						</li>
						<li>afin de vous contacter concernant votre compte et fournir une assistance à la clientèle, notamment en répondant à vos commentaires et vos questions,</li>
						<li>
							afin de vous tenir informé sur le Service, les fonctionnalités, les enquêtes, les lettres d’information, les offres, les concours et les événements qui, à notre avis, pourraient vous être utiles ou que vous avez
							sollicités de notre part,
							<br />
							afin de vendre ou commercialiser le Service,
						</li>
						<li>
							afin de mieux comprendre vos besoins, diagnostiquer les problèmes, analyser les tendances, améliorer les fonctionnalités et la convivialité des Services, et afin de mieux comprendre nos clients et utilisateurs et
							commercialiser les Services auprès de ceux-ci,
						</li>
						<li>afin de garantir la sécurité des Services.</li>
					</ul>
					<h4 className="title-left">2.2- Le Contenu Client</h4>
					<p>
						Lorsque nos Clients connectent un Profil social au Service, ils peuvent choisir de collecter, traiter, partager et accéder instantanément à un tel Profil social via le Service (sous réserve du respect des conditions
						d’utilisation et des politiques de confidentialité des Plateformes associées).
					</p>
					<p>
						Ainsi, nos Utilisateurs Clients peuvent obtenir, utiliser et analyser les données à partir des Plateformes associées de leur choix, mais également créer, publier, transmettre, consulter, afficher ou partager des
						données ou des informations dans le cadre du Service.
					</p>
					<p>
						<u>
							<em>Type de données concernées :</em>
						</u>
					</p>
					<ul>
						<li>
							Le contenu généré par le Client (comme des messages (y compris les messages directs ou privés), les publications, les commentaires, les photos de profil, les photographies, les flux ou les communications
							échangées sur les Plateformes associées) ;
						</li>
						<li>Les Messages et/ou noms des Utilisateurs finaux ;</li>
						<li>Les URL des Profils sociaux des Utilisateurs finaux sur les Plateformes associées ;</li>
						<li>Les identifiants de l’utilisateur sur les Plateformes associées.</li>
					</ul>
					<p>
						<u>
							<em>Comment nous les utilisons</em>
						</u>
					</p>
					<p>Imodo utilise le Contenu Client aux fins suivantes :</p>
					<ul>
						<li>Afin d’identifier l’auteur d’un Message de l’Utilisateur final ;</li>
						<li>Afin de regrouper tous les messages de l’Utilisateur final adressés par la même personne et fournir un historique de toutes les conversations passées entre l’Utilisateur final et un Client.</li>
					</ul>
					<p>
						<em>Les applications et réseaux sociaux tiers</em>
					</p>
					<p>
						Le Service déploie un écosystème au sein duquel vous pouvez choisir de vous connecter aux Plateformes associées. Si vous décidez d’envoyer votre contenu vers une Plateforme associée, Imodo déclinera toute
						responsabilité à l’égard de ce contenu à partir du moment où il quitte le Service. En effet, le contenu sera régi par les conditions générales et les politiques de confidentialité des Plateformes associées. Par
						exemple, si vous utilisez le Service pour publier un message sur Facebook, la politique de confidentialité et les conditions générales d’utilisation de Facebook s’appliqueront à ce message sur le serveur de Facebook.
						Si nous nous efforçons effectivement de faciliter l’accès uniquement aux Plateformes associées qui partagent notre engagement en termes de confidentialité, nous déclinons toute responsabilité à l’égard des contenus
						ou des politiques de confidentialité de toute Plateforme associée. Nous vous encourageons à lire attentivement les politiques de confidentialité de toute Plateforme associée à laquelle vous accédez via les Services.
					</p>
					<p>À titre de référence, les politiques de confidentialité de nos Plateformes associées actuelles sont les suivantes :</p>
					<ul>
						{/* <li><a href="https://www.youtube.com/yt/about/policies/#community-guidelines" target="_blank" rel="noopener">https://www.youtube.com/yt/about/policies/#community-guidelines</a></li>
          <li><a href="https://twitter.com/privacy" target="_blank" rel="noopener">https://twitter.com/privacy</a></li>
           */}
						<li>
							<a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener">
								https://www.facebook.com/policy.php
							</a>
						</li>
						<li>
							<a href="https://help.instagram.com/402411646841720" target="_blank" rel="noopener">
								https://help.instagram.com/402411646841720
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener">
								https://www.linkedin.com/legal/privacy-policy
							</a>
						</li>
						<li>
							<a href="http://www.google.com/policies/privacy" target="_blank" rel="noopener">
								http://www.google.com/policies/privacy
							</a>
						</li>
					</ul>
					<h4 className="title-left">2.3- Révocation de l’accès aux Plateformes associées</h4>
					<p>
						Étant donné que le Service s’appuie entièrement sur ses connexions aux Plateformes associées et leurs API (telles que l’API Facebook, l’API d’Instagram, l’API de Twitter, l’API de LinkedIn et les services API de
						YouTube), vous devrez procéder de la façon suivante si vous souhaitez vous assurer que le Service n’accède pas aux données des Profils sociaux que vous avez précédemment connectés au Service.
					</p>
					<ul>
						<li>
							Pour YouTube:&nbsp;
							<a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noopener">
								https://security.google.com/settings/security/permissions
							</a>
						</li>
						<li>
							Pour Twitter:&nbsp;
							<a href="https://help.twitter.com/en/managing-your-account/connect-or-revoke-access-to-third-party-apps" target="_blank" rel="noopener">
								https://help.twitter.com/en/managing-your-account/connect-or-revoke-access-to-third-party-apps
							</a>
						</li>
						<li>
							Pour Facebook:&nbsp;
							<a href="https://www.facebook.com/help/204306713029340" target="_blank" rel="noopener">
								https://www.facebook.com/help/204306713029340
							</a>
						</li>
						<li>
							Pour Instagram:&nbsp;
							<a href="https://www.facebook.com/help/204306713029340" target="_blank" rel="noopener">
								https://www.facebook.com/help/204306713029340
							</a>
						</li>
						<li>
							Pour LinkedIn:&nbsp;
							<a href="https://www.linkedin.com/pulse/remove-third-party-apps-connected-your-linkedin-hector-rodriguez/" target="_blank" rel="noopener">
								https://www.linkedin.com/pulse/remove-third-party-apps-connected-your-linkedin-hector-rodriguez/
							</a>
						</li>
					</ul>
					<h3 className="section-title-left">3- Cookies et technologies liées</h3>
					<p>
						À l’instar de nombreux sites internet, nous collectons certaines informations au moyen de « cookies », de petits fichiers texte sauvegardés par votre navigateur lorsque vous accédez à notre Site. Nous sommes
						susceptibles d’utiliser des cookies de session et des cookies persistants pour être informés lorsque vous vous connectez au Site et pour obtenir des informations indiquant quand et comment vous interagissez avec
						notre Site. Nous sommes également susceptibles d’utiliser des cookies pour superviser l’utilisation globale et suivre le trafic sur notre Site, mais aussi pour personnaliser et améliorer notre Service.
					</p>
					<p>En visitant le Site ou en utilisant le Service, vous consentez à l’utilisation de cookies sur votre navigateur et via des courriers électroniques au format HTML.</p>
					<p>By visiting the Site or using the Service you agree to the use of cookies in your browser and HTML based emails.</p>
					<h3 className="section-title-left">4- Comment vos informations sont-elles sécurisées ?</h3>
					<p>
						<strong>La sécurité de vos informations personnelles est au centre de nos préoccupations.</strong>
					</p>
					<p>
						Imodo a mis en œuvre diverses mesures pour garantir que les informations sont protégées de manière appropriée contre tout accès, utilisation, divulgation et destruction non autorisés. Veuillez garder à l’esprit que
						s’il ne peut être éliminé, le risque peut être considérablement atténué et réduit. Vous devez également être conscients que l’envoi d’informations sur Internet n’est jamais entièrement sécurisé.
					</p>
					<p>
						Toutes les mesures prises par Imodo réduisent considérablement le risque. Les tiers, y compris vous-même, ne sauraient invoquer la responsabilité d’Imodo, au titre de tout accès, toute utilisation et / ou toute
						divulgation non autorisée d’informations, pour autant que ces actes ne découlent pas d’une négligence grave, d’une faute intentionnelle, d’une fraude ou d’un acte commis de mauvaise foi imputable à Imodo.
					</p>
					<p>Les mesures de sécurité prises par Imodo sont les suivantes :</p>
					<ul>
						<li>L’accès aux informations stockées sur les serveurs d’Imodo est limité à un nombre restreint d’employés d’Imodo ;</li>
						<li>
							Nous appliquons les meilleures pratiques du secteur pour garantir la sécurité de toutes les informations collectées et / ou transmises aux Plateformes associées. Le Site et le Service sont sécurisés via le
							protocole SSL, qui assure le cryptage de toutes les données transmises ;
						</li>
						<li>
							Les serveurs d’Imodo sont protégés par a) des pare-feux établissant une barrière entre notre réseau interne fiable et sécurisé et Internet, et b) des restrictions d’IP, limitant l’accès aux adresses figurant sur
							la liste blanche ;
						</li>
						<li>
							Chaque Client peut accéder uniquement aux informations relatives à son Profil social qu’il surveille et aux Utilisateurs finaux spécifiques interagissant avec un tel Profil social. En ce qui concerne Twitter et
							Instagram, chaque Client pourra également accéder à du contenu public publié par les Utilisateurs finaux et contenant des mots clés spécifiques ;
						</li>
						<li>
							Imodo a recours à des fournisseurs tiers et des partenaires d’hébergement (comme Amazon AWS) pour fournir le matériel, les logiciels, la mise en réseau, l’espace de stockage et la technologie liée nécessaires à
							l’exécution des Services. Ces fournisseurs proposent tous une sécurité inégalée. Vous pouvez consulter les fonctions de sécurité d’Amazon en cliquant sur le lien suivant : aws.amazon.com/security ;
						</li>
						<li>Lorsque les paiements sont effectués par carte de crédit, Imodo a recours à des fournisseurs tiers se conformant à la norme PCI_DSS ;</li>
						<li>Imodo analyse périodiquement ses pratiques de collecte et de traitement des informations et, le cas échéant, examinera et modifiera la présente Politique de confidentialité en conséquence ;</li>
						<li>
							Imodo a recours à des fournisseurs tiers pour empêcher les attaques contre le Site ou le Service, ainsi que la destruction ou le vol de ses données. De tels prestataires surveillent également les attaques
							potentielles pour permettre à Imodo d’informer ses Clients dans le cas où leur Contenu Client et / ou les Données Imodo seraient compromis.
						</li>
					</ul>
					<p>
						Imodo prend des mesures de sécurité réputées raisonnables pour protéger vos informations personnelles et éviter toute perte, toute utilisation abusive, tout accès non autorisé, toute divulgation, toute modification
						et destruction. Sachez toutefois qu’en dépit de nos efforts, aucune mesure de sécurité n’est complètement infaillible.
					</p>
					<h3 className="section-title-left">5- Vos droits : Accès, modification, suppression, divulgation</h3>
					<p>Vous avez le droit de vous opposer au traitement et à la collecte de vos données à caractère personnel et de retirer votre consentement à tout moment, sauf disposition contraire de la loi en vigueur.</p>
					<p>Chaque fois que vous retirez votre consentement, vous entendez et convenez que cela mettra immédiatement fin à votre utilisation du Service.</p>
					<p>
						En conséquence, vous pouvez demander la modification ou la suppression de vos informations à tout moment en envoyant un courrier électronique à{' '}
						<a href="mailto:contact@Imodo.ai" target="_blank" rel="noopener">
							contact@Imodo.ai
						</a>
						, en précisant votre nom, votre prénom et l’URL de tous les Profils sociaux que vous avez connectés au Service. Une telle demande sera adressée depuis l’adresse e-mail utilisée pour créer votre compte sur le Service.
					</p>
					<p>
						Veuillez noter qu’il est possible que certaines informations personnelles soient conservées par Imodo pendant une période spécifique suivant l’annulation de votre compte, pour autant que cela soit nécessaire à la
						poursuite de nos objectifs commerciaux légitimes ou si cela était requis ou autorisé par la loi en vigueur.
					</p>
					<p>
						Gardez à l’esprit qu’Imodo fait office de canal entre nos Clients et les différentes Plateformes associées que nous desservons. Dans plusieurs cas, le contenu publié via Imodo ne sera pas maîtrisé ou placé sous le
						contrôle d’Imodo, étant précisé qu’il est possible que tout contenu que vous avez partagé sur une Plateforme associée via les Services soit toujours accessible par les tiers et le grand public, étant donné que le
						contenu sera désormais placé sous le contrôle des opérateurs des Plateformes associées.
					</p>
					<p>
						Imodo ne loue ni ne vend vos Données à caractère personnel Client ou le contenu Client, étant cependant entendu que nous pouvons divulguer ces données à un nombre limité de tiers de confiance dans les situations
						décrites ci-dessous, ce à quoi vous consentez explicitement en utilisant notre Service. Nous divulguerons vos informations personnelles lorsqu’il nous incombe de le faire, sur le fondement de la loi ou d’une
						ordonnance judiciaire, et afin de nous conformer à toute exigence ou obligation légale ou réglementaire. Nous déploierons tous les efforts raisonnables pour nous assurer que ces exigences ou obligations sont
						conformes aux lois en vigueur.
					</p>
					<p>
						Nous nous réservons le droit de divulguer vos informations à des tiers si nous estimions, sur le fondement d’informations raisonnables, que la divulgation est nécessaire aux fins d’une enquête et / ou afin de se
						prévaloir des violations des Conditions d’utilisation (le cas échéant), pour détecter, prévenir ou lutter autrement contre la fraude et remédier aux problèmes de sécurité et aux problèmes techniques ou à d’autres
						irrégularités ou illégalités, ou pour protéger les droits et intérêts, ainsi que la propriété d’Imodo.
					</p>
					<p>Nous nous réservons le droit de divulguer vos informations à des sociétés mères, des filiales, des coentreprises ou autres sociétés placées sous contrôle conjoint avec Imodo.</p>
					<p>
						Nous nous réservons le droit de divulguer vos informations dans le cas où nous serions acquis ou fusionnés avec une autre entité (auquel cas nous demanderons à cette entité de remplir les obligations nous incombant
						conformément à la présente Politique de confidentialité ou de vous informer que vous relevez d’une nouvelle politique de confidentialité).
					</p>
					<h3 className="section-title-left">6- Notifications et révision de la Politique de confidentialité</h3>
					<p>
						Si vous avez des inquiétudes concernant le respect de votre vie privée, nous vous prions d’adresser un courrier électronique à Imodo à l’adresse{' '}
						<a href="mailto:contact@Imodo.ai" target="_blank" rel="noopener">
							contact@Imodo.ai
						</a>
						,en décrivant de manière détaillée vos inquiétudes. Imodo déploiera ses meilleurs efforts pour résoudre ces problèmes dans un délai raisonnable.
						<br />
						Nous sommes susceptibles de modifier la présente Politique de confidentialité à tout moment.
						<br />
						La version la plus récente de la politique, disponible à l’adresse <a href="https://Imodo.ai/politique-de-confidentialite">https://Imodo.ai/politique-de-confidentialite</a> régira l’utilisation que nous ferons de vos
						informations collectées et traitées dans le cadre du Service.
					</p>
					<h4 className="title-left">6.1- Consentement</h4>
					<p>
						En utilisant le Service, ou en envoyant des informations personnelles dans le cadre du Service ou en collectant des informations personnelles à partir de Plateformes associées via le Service, vous consentez à la
						collecte, au transfert, au stockage, à la divulgation et à l’utilisation de vos informations personnelles de la manière définie dans la présente Politique de confidentialité. À défaut de consentir à l’utilisation de
						vos informations personnelles de cette manière, vous vous abstiendrez d’utiliser le Service.
					</p>
					<h4 className="title-left">6.2- Loi applicable et résolution des litiges</h4>
					<p>
						La présente Politique de confidentialité est partie intégrante de nos Conditions d’utilisation.
						<br />
						La loi applicable et le mécanisme de résolution des litiges indiqués dans nos Conditions d’utilisation s’appliquent également à notre Politique de confidentialité.
					</p>
					<p>
						La Politique de confidentialité d’Imodo fait partie intégrante des Conditions d’utilisation du Service. Les Conditions d’utilisation du Service et la Politique de confidentialité s’appliquent à votre utilisation du
						Service.
					</p>
				</div>
			</section>
		</>
	)
}

export default ConfidentFr
