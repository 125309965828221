import React from 'react'
import { withRouter,useHistory,Link } from 'react-router-dom'
import './footer.css'
const FB_ICON = require('../../assets/images/footer_facebook.svg');
const YOUTUBE_ICON = require('../../assets/images/footer_youtube.svg');  
const LINKEDIN_ICON = require('../../assets/images/footer_linkedin.svg') 
const INSTA_ICON = require('../../assets/images/footer_instagram.svg');  
/* const YOUTUBE_ICON = require('../../assets/images/footer_youtube.svg');     
const INSTA_ICON = require('../../assets/images/footer_instagram.svg ');     
const LINKEDIN_ICON = require('../../assets/images/footer_linkedin.svg '); */     
   
 
          

function Footer() {
	const history = useHistory()
	return (
		<div id="footer-modo" className=" d-flex justify-content-center">
			<div className="d-flex justify-content-center flex-wrap policy-links">
				<p>© 2021 IMODO</p>
				<p>
					<Link to='/politique-de-confidentialite' onClick={(e)=>{
						//e.preventDefault()
						//history.push('/politique-de-confidentialite')
					}}>Politique de Confidentialité</Link>
				</p>
				{/* <div className="social-links">
					<a href="https://www.facebook.com/commentsautoreply" target="_blank" title="Agorapulse Facebook page" rel="noopener">
						<img className="lozad" width={24} height={24} src={FB_ICON} />					
                    </a>
					<a href="https://www.facebook.com/commentsautoreply" target="_blank" title="Agorapulse Instagram profile" rel="noopener">
						<img className="lozad" width={24} height={24}src={INSTA_ICON} />					
                    </a>
					<a href="https://www.facebook.com/commentsautoreply" target="_blank" title="Agorapulse Twitter account" rel="noopener">
						<img className="lozad" width={24} height={24} src={LINKEDIN_ICON} />					
                    </a>
                    <a href="https://www.youtube.com/watch?v=KJN4XzJNvSQ" target="_blank" title="Agorapulse Twitter account" rel="noopener">
						<img className="lozad" width={24} height={24} src={YOUTUBE_ICON} />					
                    </a>
				</div> */}
			</div>
			</div>
	)
}

export default withRouter(Footer)
